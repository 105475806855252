import React from "react";
import './whychoose.css'
import { FaCar } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { BsFillEmojiSmileFill } from "react-icons/bs";

const WhyChoose = () => {
  return (
    <div className='banner-whychoose mt-24 z-[-1] '>
      <div className='container-custom relative'>
        <div className='flex justify-center items-center gap-5 pt-5'>
            <h3 className='text-xl text-center'>
              About our <span className='subhead-mark'>YD Nippon</span>
            </h3>
        </div>
        <div className='flex justify-center items-center gap-5 pt-4'>
            <h2 className='text-3xl md:text-4xl text-center font-bold'>Why Choose Us YD Nippon</h2>
        </div>
        <div className='flex justify-center items-center gap-10 pt-6 max-w-[650px] mx-auto'>
            <p className='justify-center text-center'>
                   Experience exceptional service, unmatched quality, and personalized vehicle solutions with YD Nippon. Your satisfaction guaranteed
            </p>
        </div>
        <div className='grid-container pt-10'>
            <div data-aos="fade-right" data-aos-duration="800" class="rounded relative overflow-hidden shadow-lg bg-white  h-[350px] xl:h-[300px] flex justify-center items-center basis-[33%]">
                <BsFillEmojiSmileFill size={100} color="#2ABB52" className="absolute top-7 right-7 opacity-10"   />
                <div class="px-10 py-16 md:py-10">
                    <div className="bg-[#2ABB52] rounded-full w-fit p-3 " >
                      <BsFillEmojiSmileFill size={40} color="#fff" />
                    </div>
                    <div class="font-bold text-xl my-4">Customer Satisfaction</div>
                    <p class="text-gray-700 text-base">
                             At YDNippon Co. Ltd, your satisfaction is our priority. We ensure exceptional service and a delightful vehicle purchase experience
                    </p>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="800" class="rounded relative overflow-hidden shadow-lg bg-white h-[350px] xl:h-[300px] flex justify-center items-center basis-[33%]">
                <MdEmail size={100} color="#FC0012" className="absolute top-7 right-7 opacity-10"   />
                <div class="px-10 py-16 md:py-10">
                    <div className="bg-[#FC0012] rounded-full w-fit p-3" >
                         <MdEmail size={40} color="#fff" />
                    </div>
                    <div class="font-bold text-xl my-4">Vehicle Request</div>
                    <p class="text-gray-700 text-base">
                             Customize and request your ideal car with YDNippon Co. Ltd. We find the perfect match for your needs.
                    </p>
                </div>
            </div>
            <div data-aos="fade-left"  data-aos-duration="800" class="rounded relative overflow-hidden shadow-lg bg-white h-[350px] xl:h-[300px] flex justify-center items-center basis-[33%]">
                <FaCar size={100} color="#0084FE" className="absolute top-7 right-7 opacity-10"   />
                <div class="px-10 py-16 md:py-10">
                    <div className="bg-[#0084FE] rounded-full w-fit p-3">
                        <FaCar size={40} color="#fff" />

                    </div>
                    <div class="font-bold text-xl my-4">Genuine Vehicle</div>
                    <p class="text-gray-700 text-base">
                        Trust YDNippon Co. Ltd for genuine vehicles. We guarantee authenticity and quality, ensuring high standards of reliability and performance.
                    </p>
                </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default WhyChoose;
