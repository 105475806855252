import React, { useEffect, useRef, useState } from 'react';
import './cargallery.css';
import { Gauge } from 'lucide-react';
import { MdOutlineLocalGasStation } from "react-icons/md";
import { FaCar, FaTruckFront } from "react-icons/fa6";
import {AiFillEye} from 'react-icons/ai'
import { useYdNipponContext } from '../../../context/YDNipponContext';
import { CiCreditCard1, CiCreditCard2 } from 'react-icons/ci';
import { GiGearStick } from 'react-icons/gi';

const CarGallery = () => {  
  const {favrtVehicales} = useYdNipponContext()
  const [fillterVehciale , setFillterVehicale] = useState()

  const vehicaleref = useRef()
  const lightVehicaleref = useRef()
  const longVehicaleref = useRef()

  console.log(favrtVehicales);

  useEffect(() => {
    setFillterVehicale(favrtVehicales)
  },[favrtVehicales])

  const handleVehicaleFillter = (btn , vehitype) => {
    const arr = btn.current.classList
    if(arr.contains("active-btn")){
        console.log("already have");
    }else{
        if(vehitype === "All Vehicale"){
            vehicaleref.current.classList.add("active-btn")
            lightVehicaleref.current.classList.remove("active-btn")
            longVehicaleref.current.classList.remove("active-btn")
            setFillterVehicale(favrtVehicales)
        }
        if(vehitype === "Light Vehicale"){
            vehicaleref.current.classList.remove("active-btn")
            lightVehicaleref.current.classList.add("active-btn")
            longVehicaleref.current.classList.remove("active-btn")
            const fillter = favrtVehicales.filter((vehi) => vehi.VehicaleType === "Light Vehicale")
            setFillterVehicale(fillter)
            console.log(fillter);
        }
        if(vehitype === "Long Vehicale"){
            vehicaleref.current.classList.remove("active-btn")
            lightVehicaleref.current.classList.remove("active-btn")
            longVehicaleref.current.classList.add("active-btn")
            const fillter = favrtVehicales.filter((vehi) => vehi.VehicaleType === "Long Vehicale")
            setFillterVehicale(fillter)
            console.log(fillter);
        }
    }
  }

  return (
    <div className='container-custom mt-24' >
        <div className='flex justify-center items-center gap-5 pt-5'>
            <h3 className='text-xl text-center font-bold'>
              Buy Dream <span className='subhead-mark'>Vehicale</span>
            </h3>
        </div>
        <div className='flex justify-center items-center gap-5 pt-4'>
            <h2 className='text-3xl md:text-4xl text-center font-bold'>Great Deal From Top Rated</h2>
        </div>
        <div className='flex justify-center items-center my-10 gap-4 flex-wrap' >
            <button onClick={() => handleVehicaleFillter(vehicaleref , "All Vehicale")} ref={vehicaleref} className='blurButton active-btn flex justify-center items-center gap-2' ><span>All Vehicale</span></button>
            <button onClick={() => handleVehicaleFillter(lightVehicaleref , "Light Vehicale")} ref={lightVehicaleref}  className='blurButton flex justify-center items-center gap-2' ><FaCar /><span>Light Vehicale</span></button>
            <button onClick={() => handleVehicaleFillter(longVehicaleref , "Long Vehicale")} ref={longVehicaleref} className='blurButton flex justify-center items-center gap-2' ><FaTruckFront /><span>Long Vehicale</span></button>
        </div>
        <div className='grid-container-01' >
            {
                fillterVehciale?.map((car) => (
                    <SingleCar car={car} />
                ))
            }
        </div>
    </div>
  )
}

const SingleCar = ({car}) => {
    const {setImageSliderStatus , setSliderImageList } = useYdNipponContext()
    const handleSlideOpen = () => {
        setImageSliderStatus(true)
        setSliderImageList(car.Images)
    }

    return(
        <div className='bg-[#fff] w-[100%] h-fit hover:shadow-xl ease-linear p-5 cursor-pointer' data-aos="fade-up"  data-aos-duration="800" >
                <div className='w-[100%] h-[300px] bg-[#F8F8F8] relative flex justify-center items-center' >
                    <img src={car.Images[0]} alt="car" className='w-full rounded-md h-full object-cover' />
                    <div className='na-image-show' onClick={handleSlideOpen} >
                        <AiFillEye />
                    </div>
                </div>
                <div className='mt-3' >
                    <h4 className='text-[18px] font-semibold' >{car?.EngineModel}</h4>
                    <p className='text-[#B1252E] font-bold text-[18px] flex justify-start items-end gap-2 mt-2' >{car.Colour}</p>
                    <div className='py-4 flex justify-between items-start gap-3 border-top mt-4' >
                        <div className='basis-[50%]' >
                            <p className='flex mt-2 justify-start gap-2 items-center' ><MdOutlineLocalGasStation   color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.EningineCapacity}</span></p>
                            <p className='flex mt-2 justify-start gap-2 items-center' ><Gauge  color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.Mileage}</span></p>
                            <p className='flex mt-2 justify-start gap-2 items-center' ><CiCreditCard2  color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.Sakken}</span></p>
                            
                        </div>
                        <div className='basis-[50%]' >
                            <p className='flex mt-2 justify-start gap-2 items-center' ><GiGearStick color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.Transmission}</span></p>
                            <p className='flex mt-2 justify-start gap-2 items-center' ><CiCreditCard1 color='#A2A2A2' size={18}  /><span className='text-[#A2A2A2] text-[13px]' >{car?.ChaseNo}</span></p>
                        </div>
                    </div>
                    <button className='secondaryButton w-[100%] mt-2' >View</button>
                </div>
        </div>
    )
}

export default CarGallery;
            