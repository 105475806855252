import React , {createContext , useContext, useState} from 'react'
import {country ,  Brand , Car_model} from '../YdDb'

const YdNippinContext = createContext()

function YDNipponContaxtProvider({children}) {
  const [selectedCountry , setSelectedCountry]  = useState(country[0]);
  const [selectedBrand , setSelectedBrand]  = useState(Brand[selectedCountry][0]);
  const [selectModel , setSelectedModel]  = useState(Car_model[selectedBrand][0]);
  const [selectyear , setSelectedYear]  = useState("");
  const [selectColur , setSelectedColour]  = useState("Pearl");
  const [selectPrice , setSelectedPrice]  = useState("");
  const [selectUPdatedPrice , setSelectedUPdatedPrice]  = useState("");
  const [selectScore , setSelectedScore]  = useState("All Score");
  const [selectMilieage , setSelectedMilieage]  = useState("All Km");

  const [siderbarStatus , setSiderbarStatus] = useState(false)
  const [popupStatus , setPopupStatus] = useState(false)
  const [re1qMailBox , setReqMailBox] = useState(false)

  const [notiStatus , setNotiStatus] = useState(false)
  const [notiError , setNotiError] = useState(false)
  const [notiContent , setNotiContent] = useState("")

  const [isLoged , setIsLoged] = useState(false)
  const [adSideBar  , adSideBarStatus] = useState(false)
  const [adSideBarMobile  , adSideBarStatusMobile] = useState(false)
  const [viewdVehicaleStatus , setViewdVehicaleStatus] = useState(false)
  const [delteconfomationStatus , setDeleteConfomrationStatus] = useState(false)
  const [viedVehicale , setViewdvehicale] = useState([])

  const [allVehicales , setAllVehicales] = useState([])
  const [fillterdVehicales , setFillterdVehicales] = useState([])
  const [favrtVehicales , setFavrtVehicales] = useState([])

  const [imageSliderStatus , setImageSliderStatus] = useState(false)
  const [sliderImageList , setSliderImageList]  = useState([])

  const [messageSendStatus , setMessageSendStatus] = useState(false)
  const [messageSend , setMessageSend] = useState([])

  const [clinetRequest , setClientRequest] = useState([])

  const [clinetRequestBoxStatus , setClinetRequestBoxStatus ] = useState(false)
  const [clinetRequestBox , setClinetRequestBox  ] = useState()

  const [user , setUser] = useState();

  const value = {
    selectedCountry : selectedCountry, 
    setSelectedCountry : setSelectedCountry,
    selectedBrand : selectedBrand, 
    setSelectedBrand : setSelectedBrand,
    selectModel : selectModel, 
    setSelectedModel : setSelectedModel,
    selectyear : selectyear, 
    setSelectedYear : setSelectedYear,
    selectColur : selectColur, 
    setSelectedColour : setSelectedColour,
    selectPrice : selectPrice, 
    setSelectedPrice : setSelectedPrice,
    selectMilieage : selectMilieage, 
    setSelectedMilieage : setSelectedMilieage,
    siderbarStatus :siderbarStatus, 
    setSiderbarStatus : setSiderbarStatus,
    popupStatus : popupStatus,
    setPopupStatus : setPopupStatus,
    selectScore : selectScore, 
    setSelectedScore : setSelectedScore,
    re1qMailBox : re1qMailBox, 
    setReqMailBox : setReqMailBox,
    notiStatus :notiStatus ,
    setNotiStatus: setNotiStatus,
    notiError : notiError, 
    setNotiError : setNotiError,
    notiContent : notiContent, 
    setNotiContent : setNotiContent,
    selectUPdatedPrice : selectUPdatedPrice,
    setSelectedUPdatedPrice: setSelectedUPdatedPrice,
    isLoged : isLoged, 
    setIsLoged : setIsLoged,
    adSideBar : adSideBar,
    adSideBarStatus : adSideBarStatus, 
    adSideBarMobile :adSideBarMobile,
    adSideBarStatusMobile :adSideBarStatusMobile,
    allVehicales : allVehicales, 
    setAllVehicales : setAllVehicales,
    fillterdVehicales : fillterdVehicales,
    setFillterdVehicales : setFillterdVehicales,
    viewdVehicaleStatus : viewdVehicaleStatus,
    setViewdVehicaleStatus : setViewdVehicaleStatus,
    viedVehicale : viedVehicale,
    setViewdvehicale : setViewdvehicale,
    delteconfomationStatus :delteconfomationStatus,
    setDeleteConfomrationStatus : setDeleteConfomrationStatus,
    favrtVehicales : favrtVehicales,
    setFavrtVehicales : setFavrtVehicales,
    imageSliderStatus :imageSliderStatus , 
    setImageSliderStatus : setImageSliderStatus,
    sliderImageList : sliderImageList,
    setSliderImageList : setSliderImageList,
    messageSendStatus :messageSendStatus, 
    setMessageSendStatus: setMessageSendStatus,
    messageSend :messageSend, 
    setMessageSend :setMessageSend,
    clinetRequest : clinetRequest, 
    setClientRequest : setClientRequest,
    clinetRequestBoxStatus :clinetRequestBoxStatus,
    setClinetRequestBoxStatus : setClinetRequestBoxStatus,
    clinetRequestBox : clinetRequestBox, 
    setClinetRequestBox : setClinetRequestBox,
    user : user, 
    setUser : setUser
  }

  return (
    <YdNippinContext.Provider value={value}>
            {children}
    </YdNippinContext.Provider>
  )
}

export const useYdNipponContext = () => (
    useContext(YdNippinContext)
)

export default YDNipponContaxtProvider