import React  from 'react'
import './VehicaleManagePage.css'
import {motion} from 'framer-motion'
import SideBar from '../components/SideBar/SideBar';
import Nav from '../components/Nav/Nav';
import PopupBlack from '../components/PopupBlack/PopupBlack';
import {Link} from 'react-router-dom'
import {BiSolidAddToQueue} from 'react-icons/bi'
import ShowCaseAllVehicale from './components/ShowCaseAllVehicale/ShowCaseAllVehicale';
import {FaCar } from 'react-icons/fa'
import ViewdVehicale from '../components/ViewdVehicale/ViewdVehicale';
import DeleteConformation from '../components/DeleteConformation/DeleteConformation';
import Notification from '../../HomePage/Notification/Notification';
import ImageSlider from '../../HomePage/ImageSlider/ImageSlider';
import { useYdNipponContext } from '../../../context/YDNipponContext';

function VehicaleManagePage() {
  const {adSideBar  ,  setPopupStatus , adSideBarStatusMobile} = useYdNipponContext()


  const handleSidebar = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  return (
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
           <SideBar page='vehicales' dashbord='../admin/adminpanel' vehicale='./' request = '../admin/adminpanel/requested-vehicale' favourite='../admin/adminpanel/favourite-vehicale'/>
           <ViewdVehicale />   
           <DeleteConformation />
           <Notification />
           <ImageSlider />
           <div className={`main-admin-home vehicale-manage ${adSideBar  && 'main-admin-home-full'}`} >
                <Nav />
                <div className='main-admin-innner' >
                    <h1><FaCar  /><span>Manage Vehicale</span></h1>
                    <button className='add-btn primaryBtn'><Link className='flex-btn'  to='add-vehicale'><BiSolidAddToQueue /><span>Add New Vehicale</span></Link></button>
                    <ShowCaseAllVehicale />
                </div>
           </div>
           <PopupBlack handleSidebar={handleSidebar} />
        </motion.div>
  )
}

export default VehicaleManagePage