import React , { useEffect, useRef, useState} from 'react'
import './Login.css'
import logo from '../../../assets/logos/yd-nippon.png'
import {GrMail} from 'react-icons/gr'
import {AiFillEye  , AiFillEyeInvisible} from 'react-icons/ai'
import {Link , useNavigate} from 'react-router-dom'
import { useYdNipponContext } from '../../../context/YDNipponContext'
import Notification from '../../HomePage/Notification/Notification'
import {auth} from '../../../firebase-config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import {motion} from 'framer-motion'
import PreLoader from '../../HomePage/PreLoader/PreLoader'

function LoginPage() {
  const passwordRef = useRef()
  const navigate = useNavigate()

  const [passwordShow , setPasswordShow] = useState(false)
  const [showRoute  , setShowRoute] = useState(false)
  const [password , setPassword] = useState("")
  const [email , setEmail] = useState("")
  const [isLoading , setIsLoading] = useState(false)
  const {setNotiContent , setNotiStatus , setNotiError , isLoged} = useYdNipponContext()

  const handlePasswordShow = () => {
    if(!passwordShow){
      passwordRef.current.type = 'text'
      setPasswordShow(true)
    }else{
      passwordRef.current.type = 'password'
      setPasswordShow(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
       setShowRoute(true)
    },1200)
  },[])

  useEffect(() => {
      if(isLoged){
          navigate('/admin/adminpanel' ) 
      }
  },[isLoged , navigate])

  const signinCheckingForm = async (email , password) => {
    setIsLoading(true)
    if(email === "" && password ==="" ){
      setNotiContent("Please Fill All The Feild")
      setNotiStatus(true);
      setNotiError(true)
      setTimeout(() =>{
        setIsLoading(false)
            setNotiStatus(false);
      },4000)
    }else if(email === ""){
      setNotiContent("Email Feild Is Empty")
      setNotiStatus(true);
      setNotiError(true)
      setTimeout(() =>{
        setIsLoading(false)
            setNotiStatus(false);
      },4000)
    }else if(password === ""){
      setNotiContent("Password Feild Is Empty")
      setNotiStatus(true);
      setNotiError(true)
      setTimeout(() =>{
        setIsLoading(false)
            setNotiStatus(false);
      },4000)
    }else{
          if(password.length < 6 ){
            setNotiContent("Password should be at least 6 characters")
            setNotiStatus(true);
            setNotiError(true)
            setTimeout(() =>{
                  setIsLoading(false)
                  setNotiStatus(false);
            },4000)
          }else{
              if(!email.includes("@")){
                setNotiContent("Email Not In a Correct Format")
                setNotiStatus(true);
                setNotiError(true)
                setTimeout(() =>{
                      setIsLoading(false)
                      setNotiStatus(false);
                },4000)
              }else{
                try {
                   await signInWithEmailAndPassword(auth , email , password)
                   setEmail("")
                   setPassword("")
                   setNotiContent("Loged Successfully")
                   setNotiStatus(true);
                   setNotiError(false)
                   setTimeout(() =>{
                         setIsLoading(false)
                         setNotiStatus(false);
                   },4000)
                } catch (error) {
                  console.log(error);
                   if(error.message === "Firebase: Error (auth/invalid-credential)."){
                       setNotiContent("Invalid Password Or Email")
                       setNotiStatus(true);
                       setNotiError(true)
                       setTimeout(() =>{
                             setIsLoading(false)
                             setNotiStatus(false);
                       },4000)
                   }else if(error.message === "Firebase: Error (auth/wrong-password)."){
                       setNotiContent("Invalid Password")
                       setNotiStatus(true);
                       setNotiError(true)
                       setTimeout(() =>{
                             setIsLoading(false)
                             setNotiStatus(false);
                       },4000)
                   }
                  
                }
              
              }
          }
     }
  }  

  const handleSignIn = (e) => {
    e.preventDefault()
    signinCheckingForm(email , password)
  }

  return (
    <>
      {
        showRoute && (

          <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.7}}>
            <div className='w-full h-[110vh] relative' >
              <div className='absolute w-full h-full md:h-fit md:top-[50%] top-[0%] transform md:-translate-y-[50%]'>
                  <div className='flex realtive max-w-[1340px] mx-auto items-center px-0 md:px-6 flex-col-reverse md:flex-row'>
                      <div className='flex-1 lg:flex-[45%] px-4 py-6 bg-white  w-[90%]  rounded-lg transform translate-x-0 -translate-y-[130px] md:translate-y-0 md:translate-x-40 shadow-lg'>
                          <div className='mt-3 mb-6' >
                          <h2 className='font-bold text-center mb-1 text-[20px]' >YD NIPPON Co.Ltd - Admin Panel</h2>
                          <h5 className='text-center' >Japan Biggest Vehicale Suppliers All Around The World</h5>
                          </div>
                          <form onSubmit={(e) => handleSignIn(e)}>
                          <div className='all-form-feild' >
                              <div className='lpfc-form-feild' >
                                  <label>Email Address</label>
                                  <div className='lpfc-input' >
                                      <input value={email} type='text' placeholder='Enter Email Address' onChange={(e) => setEmail(e.target.value)} />
                                      <GrMail />
                                  </div>
                              </div>
                              <div className='lpfc-form-feild' >
                                  <label>Password</label>
                                  <div className='lpfc-input' >
                                      <input value={password} type='password' placeholder='Enter Password' ref={passwordRef} onChange={(e) => setPassword(e.target.value)} />
                                      {
                                        passwordShow ? 
                                        (<AiFillEyeInvisible onClick={handlePasswordShow} />)
                                        :
                                        (<AiFillEye onClick={handlePasswordShow} />)
                                      }
                                  </div>
                              </div>
                              <button className='login-btn'>
                                {
                                  !isLoading ? <span>Sign In</span> : <PreLoader />
                                }
                              </button>
                              <p className='mt-[10px] text-center' > <span>Not a admin visit ? </span>  <Link  className='text-[#B1252E] decoration-solid underline' to='../../'>Home</Link></p>
                          </div>
                        </form>
                      </div>
                      <div className='flex-1 lg:flex-[65%]'>
                          <img src='https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iKcg4gNk4l9k/v0/-1x-1.jpg' alt='LoginPic' className='rounded-none h-[350px] w-[767px] md:rounded-lg md:h-[550px] md:w-[1200px]' />
                      </div>
                  </div>
              </div>
            </div>
            <Notification />
          </motion.div>
        )
      }
    </>

  )
}

export default LoginPage