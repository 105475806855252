import React from 'react'
import aboutImg from '../../../assets/about/man.png'

const About = () => {
  return (
    <div className='container-custom gap-[40px] mt-24 flex flex-col justify-between items-center lg:flex-row' >
        <div className='basis-[50%] relative '  data-aos="fade-right" data-aos-duration="800" >
            <div className='absolute top-0 right-0' >
                <h3 className='w-full text-right text-4xl  xxxsm:text-5xl xxsm:text-6xl xsm:text-8xl text-[#B1252E] font-bold' >10+</h3>
                <p className=' text-right text-[14px] xxsm:text-[16px] text-black font-semibold w-[140px] xxxsm:w-[150px] xxsm:w-[200px]' >Year'r Experience Car Rental Service</p>
            </div>
            <img className='object-cover w-full h-full' src={aboutImg} alt='aboutImg' />
        </div>
        <div className='basis-[50%]'  data-aos="fade-left" data-aos-duration="800" >
            <h3 className='text-xl font-semibold'>
                 About our <span className='subhead-mark'>Yd Nippon</span>
            </h3>
            <h2 className='text-2xl xxxsm:text-3xl md:text-4xl mt-6 font-bold'>We are Providing Best Car Rental Services</h2>
            <p className='mt-[30px]  mb-[10px] text-gray-500 leading-[30px]' >At YD Nippon, we're dedicated to providing authentic vehicles globally. With an unwavering commitment to authenticity and quality, we ensure that every customer receives dependable, high-performance cars, regardless of location. Trust us to fulfill your global vehicle requirements, delivering excellence and peace of mind wherever you are in the world.</p>
            <button className='primaryBtn mt-[10px] blackBtn  !px-[25px]' >Explore More</button>
        </div>
    </div>
  )
}

export default About