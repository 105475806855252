import React from 'react'
import './PopupBlack.css'
import { useYdNipponContext } from '../../../context/YDNipponContext'

function PopupBalck() {
  const {popupStatus , setPopupStatus , setSiderbarStatus , setReqMailBox} = useYdNipponContext()

  const handlePopStaus = () => {
    setPopupStatus(false)
    setSiderbarStatus(false)
    setReqMailBox(false)
  }

  return (
    <div onClick={handlePopStaus} className={`Popup ${popupStatus && 'popup-open'}`} >

    </div>
  )
}

export default PopupBalck