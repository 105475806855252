import React  from 'react'
import './ShowCaseAllVehicale.css'
import { DataGrid } from '@mui/x-data-grid'
import {AiFillEye} from 'react-icons/ai'
import { useYdNipponContext } from '../../../../../context/YDNipponContext'

// id : obj.id,
// Image : obj.Images[0],
// EningineCapacity : obj.EningineCapacity,
// EngineModel : obj.EngineModel,
// Colour : obj.Colour,
// Mileage : obj.Mileage,
// Transmission : obj.Transmission,
// VehicaleType : obj.VehicaleType ,
// ISfavourite : obj.ISfavourite,

const columns = [
    { field: 'id', headerName: 'ID', flex: 1 , cellClassName: 'center-align' },
    { field: 'Image', headerName: 'Image', flex: 1, heigh:120,minWidth: 100, cellClassName: 'center-align',
      sortable : false,
      renderCell : (params) => {

        return (
            <div  className='image-cell data-table-cell' >
                <img src={params.row.Image} alt='cell-img' />
            </div>
        )
      }
    },
    { field: 'EningineCapacity', headerName: 'Eningine Capacity', flex: 1 ,minWidth: 130, cellClassName: 'center-align' },
    { field: 'EngineModel', headerName: 'Engine Model',flex: 1 ,  minWidth: 130, },
    { field: 'Colour', headerName: 'Colour',flex: 1 ,  minWidth: 60, },
    { field: 'Mileage', headerName: 'Mileage', flex: 1  ,   minWidth: 60,},
    { field: 'Transmission', headerName: 'Transmission', flex: 1 ,    minWidth: 120,  },
    { field: 'VehicaleType', headerName: 'Vehicale Type', flex: 1 ,    minWidth: 100,  },
  ];


  

function ShowCaseAllVehicale() {
  const {fillterdVehicales , setViewdVehicaleStatus ,  setViewdvehicale  , allVehicales} = useYdNipponContext()

  const actionColumn = [
    {
        feild: 'View', 
        headerName: 'View', 
        flex: 1,
        sortable : false,
        minWidth: 120,
        renderCell : (params) => {
            const handleView = () => {
              const fillterdVehicale = allVehicales.filter((item) => item.id === params.row.id)
              setViewdvehicale(fillterdVehicale[0])
              setViewdVehicaleStatus(true)
            }

            return(
                <div className='button-cell' onClick={handleView} >
                         <button className='cell-buttton-view' ><AiFillEye /><span>View</span></button>
                </div>
            )
        }
    }
  ]

  
  return (
    <div className='show-case-vehicle-container'>
       <div className='show-case-vehicle-container-inner'>
          <h3>Available Vehicales</h3>
          <div className='data-table'>
                <DataGrid
                    rows={fillterdVehicales}
                    rowHeight={85}
                    columns={columns.concat(actionColumn)}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
             </div>
        </div>
    </div>
  )
}

export default ShowCaseAllVehicale