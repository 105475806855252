import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAK3kDdIpirC4HdYBNWG9Gs_rXGwojT-YE",
  authDomain: "yd-nippon.firebaseapp.com",
  projectId: "yd-nippon",
  storageBucket: "yd-nippon.appspot.com",
  messagingSenderId: "932461593839",
  appId: "1:932461593839:web:bdc4b93c3e7e9f3b22071a"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)