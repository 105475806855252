import React from 'react'
import './Footer.css'
import { AiOutlineMessage } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import { IoMdAlarm } from "react-icons/io";
import logo from '../../../assets/logos/yd-nippon-white.png'
import AnchorLink from 'react-anchor-link-smooth-scroll' 
import { Cars } from '../../../YdDb';

const Footer = () => {
  return (
    <div className='footer-banner mt-24' >
        <div className='container-custom' >
            {/* Top Foter */}
            <div className='flex justify-between flex-col md:flex-row items-center gap-4 pt-[80px] pb-[60px] border-bottom' >
                <div className='basis-[33%] w-full  flex justify-center items-center flex-col gap-4' data-aos="fade-right"  data-aos-duration="800">
                    <a href="https://wa.me/+819050040518"  target="_blank" rel="noreferrer" className='bg-[#88293027] rounded-full p-5 w-fit' >
                        <AiOutlineMessage size={25} color='#B1252E' />
                    </a>
                    <h1 className='text-white text-2xl font-bold' >Need Any Help ?</h1>
                    <p className='text-center text-[14px] text-gray-300' >Monotonectally simplify frictionless communities via clicks-and-mortar minate relationships </p>
                    <button className='secondaryButton footer-btn' ><a href="https://wa.me/+819050040518" target="_blank" rel="noreferrer">Chat Now</a></button>
                </div>
                <div className='basis-[33%] w-full  flex justify-center items-center flex-col gap-4' data-aos="fade-up"  data-aos-duration="800" >
                    <a href="tel:+819050040518"  target="_blank" rel="noreferrer" className='bg-[#88293027] rounded-full p-5 w-fit' >
                        <FiPhoneCall size={25} color='#B1252E' />
                    </a>
                    <h1 className='text-white text-2xl font-bold' >Get In Touch</h1>
                    <p className='text-center text-[14px] text-gray-300' >Monotonectally simplify frictionless communities via clicks-and-mortar minate relationships </p>
                    <h3 className='text-[#B1252E] text-2xl lg:text-3xl font-bold' ><a href="tel:+819050040518"  target="_blank" rel="noreferrer" >+90 15 46 47 98 487</a></h3>
                </div>
                <div className='basis-[33%] w-full  flex justify-center items-center flex-col gap-4' data-aos="fade-left"  data-aos-duration="800" >
                    <AnchorLink href='#request' className='bg-[#88293027] rounded-full p-5 w-fit' >
                        <MdOutlineMarkEmailRead size={25} color='#B1252E' />
                    </AnchorLink>
                    <h1 className='text-white text-2xl font-bold' >Request Vehicale </h1>
                    <p className='text-center text-[14px] text-gray-300' >Monotonectally simplify frictionless communities via clicks-and-mortar minate relationships </p>
                    <button className='secondaryButton footer-btn' ><AnchorLink href='#request' >Request</AnchorLink></button>
                </div>
            </div>
            {/* Bottom Footer */}
            <div className='py-[40px] flex flex-col flex-wrap lg:flex-nowrap md:flex-row justify-between items-start  gap-10 border-bottom' >
                <div className='basis-[30%]' data-aos="fade-up"  data-aos-duration="800">
                    <img src={logo } alt='logo' className='w-[120px] object-contain' />
                    <p className='text-[14px] text-gray-300 mt-2 max-w-[320px]' >Monotonectally simplify frictionless communities via clicks-and-mortar minate relationships </p>
                    <div className='mt-7' >
                        <p className='text-[14px] text-gray-300 flex justify-start items-center gap-2 my-2' ><FiPhoneCall size={18} color='#B1252E' /><span>+90 15 46 47 98 487</span></p>
                        <p className='text-[14px] text-gray-300 flex justify-start items-center gap-2 my-2' ><MdMailOutline size={18} color='#B1252E' /><span>info@ydnippon@gmail.com</span></p>
                        <p className='text-[14px] text-gray-300 flex justify-start items-center gap-2 my-2' ><IoMdAlarm  size={18} color='#B1252E' /><span>Mon - Sat 8:00 AM 4:oo PM</span></p>
                    </div>
                </div>
                <div className='basis-[20%]' data-aos="fade-up"  data-aos-duration="800">
                        <h3 className='text-xl text-white font-semibold' >Quick Links</h3>
                        <div className='mt-[40px]' >
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >Home</a></p>
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >About</a></p>
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >Contact</a></p>
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >Service</a></p>
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >Request</a></p>
                        </div>
                </div>
                <div className='basis-[20%]' data-aos="fade-up"  data-aos-duration="800" >
                        <h3 className='text-xl text-white font-semibold' >Others</h3>
                        <div className='mt-[40px]' >
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >Home</a></p>
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >About</a></p>
                            <p className='my-2' ><a href='/' className='text-gray-400 hover:text-gray-50 ease-in text-[15px]' >Contact</a></p>
                        </div>
                </div>
                <div className='basis-[30%] w-full' data-aos="fade-up"  dat>
                        <h3 className='text-xl text-white font-semibold' >YD Nippon Gallery</h3>

                        <div className='image-container mt-[40px]' >
                            {
                                Cars[0].images.map((img) => (  
                                    <div>    
                                        <img src={img} alt='post5' />
                                    </div> 
                                ))
                            }    
                        </div>
                </div>
                
            </div>
            {/* Copyright */}
            <div className='py-[30px] flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row items-center' >
                  <p className='text-gray-50 text-[15px] w-full text-center' >Designed And Developed By <a href="https://umairlafir.com" className='text-[#B1252E]' target="_blank" rel="noreferrer">Mohamad Lafir Umair</a> </p>
                  {/* <p className='text-gray-50 text-[15px] w-full text-center' >© Copyright 2023 All rights reserved By <a href="https://wa.me/+94768383561" className='text-[#B1252E]' target="_blank" rel="noreferrer" >Design Flix</a></p> */}
            </div>
        </div>
    </div>
  )
}

export default Footer