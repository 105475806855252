import React from 'react'
import './ShowFvrtVehicales.css'
import { PiRoadHorizon } from 'react-icons/pi'
import { AiOutlineCalendar, AiOutlineStar } from 'react-icons/ai'
import { MdOutlineColorLens, MdOutlineLocalGasStation } from 'react-icons/md'
import { useYdNipponContext } from '../../../../../context/YDNipponContext'
import { Gauge } from 'lucide-react'
import { CiCreditCard1, CiCreditCard2 } from 'react-icons/ci'
import { GiGearStick } from 'react-icons/gi'

function ShowFvrtVehicales() {
  const {favrtVehicales} = useYdNipponContext()

  console.log(favrtVehicales)

  return (
    <div className='show-fvrt-vehicale-container' >
        {
            favrtVehicales?.map((car) => (
                <div className='bg-[#fff] w-[100%] h-fit hover:shadow-xl ease-linear p-5 cursor-pointer' data-aos="fade-up"  data-aos-duration="800" >
                        <div className='w-[100%] h-[200px] bg-[#F8F8F8] relative flex justify-center items-center' >
                            <img src={car.Images[0]} alt="car" className='w-full rounded-md h-full object-cover' />
                        </div>
                        <div className='mt-3' >
                            <h4 className='text-[18px] font-semibold' >{car?.EngineModel}</h4>
                            <p className='text-[#B1252E] font-bold text-[18px] flex justify-start items-end gap-2 mt-2' >{car.Colour}</p>
                            <div className='py-4 flex justify-between items-start gap-3 border-top mt-4' >
                                <div className='basis-[50%]' >
                                    <p className='flex mt-2 justify-start gap-2 items-center' ><MdOutlineLocalGasStation   color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.EningineCapacity}</span></p>
                                    <p className='flex mt-2 justify-start gap-2 items-center' ><Gauge  color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.Mileage}</span></p>
                                    <p className='flex mt-2 justify-start gap-2 items-center' ><CiCreditCard2  color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.Sakken}</span></p>
                                    
                                </div>
                                <div className='basis-[50%]' >
                                    <p className='flex mt-2 justify-start gap-2 items-center' ><GiGearStick color='#A2A2A2' size={18} /><span className='text-[#A2A2A2] text-[13px]' >{car?.Transmission}</span></p>
                                    <p className='flex mt-2 justify-start gap-2 items-center' ><CiCreditCard1 color='#A2A2A2' size={18}  /><span className='text-[#A2A2A2] text-[13px]' >{car?.ChaseNo}</span></p>
                                </div>
                            </div>
                        </div>
                </div>
            ))
        }

    </div>
  )
}

export default ShowFvrtVehicales