import React from 'react'
import {MapPin , PhoneCall , Mail} from 'lucide-react'

const TopBar = () => {
  return (
    <div className='bg-white w-full py-3 hidden md:block' >
        <div className='container-custom flex justify-between items-center' >
            <div className='flex justify-start items-center gap-4' >
                <div className='flex justify-start items-center gap-2' >
                    <MapPin color="#B1252E" size={18} />
                    <p className='text-[13px] text-[#0b163f]' >1-54 Miyoshi Yatomi-shi Aichi-ken Japan</p>
                </div>
            </div>
            <div className='flex justify-start items-center gap-4' > 
                <div className='flex justify-start items-center gap-2' >
                    <PhoneCall  color="#B1252E" size={18} />
                    <a href="tel:+819050040518"  target="_blank" rel="noreferrer" className='text-[13px] text-[#0b163f]' >+81 90 5004 0518</a>
                </div>
                <div className='flex justify-start items-center gap-2' >
                    <Mail color="#B1252E" size={18} />
                    <a href="mailto:info@ydnippon.jp"  target="_blank" rel="noreferrer" className='text-[13px] text-[#0b163f]' >info@ydnippon.jp</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopBar