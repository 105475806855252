import React  from 'react'
import './ShowCaseAllVehicale.css'
import { DataGrid } from '@mui/x-data-grid'
import { LuHeart, LuHeartOff } from 'react-icons/lu'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../../firebase-config'
import { useYdNipponContext } from '../../../../../context/YDNipponContext'

const columns = [
    { field: 'id', headerName: 'ID', flex: 1 , cellClassName: 'center-align' },
    { field: 'Image', headerName: 'Image', flex: 1, heigh:120,minWidth: 100, cellClassName: 'center-align',
      sortable : false,
      renderCell : (params) => {

        return (
            <div  className='image-cell data-table-cell' >
                <img src={params.row.Image} alt='cell-img' />
            </div>
        )
      }
    },
    { field: 'EningineCapacity', headerName: 'Eningine Capacity', flex: 1 ,minWidth: 130, cellClassName: 'center-align' },
    { field: 'EngineModel', headerName: 'Engine Model',flex: 1 ,  minWidth: 130, },
    { field: 'Colour', headerName: 'Colour',flex: 1 ,  minWidth: 60, },
    { field: 'Mileage', headerName: 'Mileage', flex: 1  ,   minWidth: 60,},
    { field: 'Transmission', headerName: 'Transmission', flex: 1 ,    minWidth: 120,  },
    { field: 'VehicaleType', headerName: 'Vehicale Type', flex: 1 ,    minWidth: 100,  },
  ];


  

function ShowCaseAllVehicale() {
  const {fillterdVehicales , setNotiStatus, setNotiError, setNotiContent  , favrtVehicales} = useYdNipponContext()


  const actionColumnFvrt = [
    {
        feild: 'Favourite', 
        headerName: 'Favourite', 
        flex: 1,
        sortable : false,
        minWidth: 100,
        renderCell : (params) => {
            const handleRemoveFvrt = () => {
               const singlevehicale = doc(db ,'vehicales' , params.row.id )
               updateDoc(singlevehicale , {
                    ISfavourite : false,
                }).then(() => {
                    setNotiContent("Vehicale Remove From Favourite List") 
                    setNotiStatus(true)
                    setNotiError(false)
                    setTimeout(() =>{
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }).catch(() => {
                    setNotiContent("Something Went Wrong Please Try Again Later") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                })
            }

            const handleAddFvrt = () => {
                if(favrtVehicales.length < 6){
                    const singlevehicale = doc(db ,'vehicales' , params.row.id )
                    updateDoc(singlevehicale , {
                        ISfavourite : true,
                    }).then(() => {
                        setNotiContent("Vehicale Added To Favourite List") 
                        setNotiStatus(true)
                        setNotiError(false)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                    }).catch(() => {
                        setNotiContent("Something Went Wrong Please Try Again Later") 
                        setNotiStatus(true)
                        setNotiError(true)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                    })
                }else{
                    setNotiContent("Only 6 vehicales You can Add To Favourite List") 
                        setNotiStatus(true)
                        setNotiError(true)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                }
               
            }

            return(
                <>
                    {
                        params?.row.ISfavourite ? 
                        (
                            <div className='button-cell' onClick={handleRemoveFvrt} >
                                <button className='cell-buttton-favrt cell-buttton-favrt-danger' ><LuHeartOff /><span>Remove Favourite</span></button>
                            </div>
                        ):
                        (
                            <div className='button-cell' onClick={handleAddFvrt} >
                                <button className='cell-buttton-favrt' ><LuHeart /><span>Add To Favourite</span></button>
                            </div>
                        )
                    }
                </>
            )
        }
    }
  ]

  
  return (
    <div className='show-case-vehicle-container show-case-fvrtt-vehicale'>
       <div className='show-case-vehicle-container-inner'>
          <h3>Available Vehicales</h3>
          <div className='data-table'>
                <DataGrid
                    rows={fillterdVehicales}
                    rowHeight={85}
                    columns={columns.concat(actionColumnFvrt)}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
             </div>
        </div>
    </div>
  )
}

export default ShowCaseAllVehicale