import React, { useState , useRef } from 'react'
import './VehicaleAddForm.css'
import {BiSolidChevronDown} from 'react-icons/bi'
import { PiEngineBold } from "react-icons/pi";
import { MdOutlineAirlineSeatReclineExtra } from "react-icons/md";
import { CiCreditCard1 , CiCreditCard2  , CiLineHeight } from "react-icons/ci";
import { IoIosColorPalette } from "react-icons/io";
import { IoRadioButtonOff, IoSpeedometerSharp } from "react-icons/io5";
import { SiGoogleearthengine } from "react-icons/si";
import { AiOutlineColumnWidth } from "react-icons/ai";
import {BsImages} from 'react-icons/bs'
import {FiUpload} from 'react-icons/fi'
import {MdDeleteOutline} from 'react-icons/md'
import { db, storage } from '../../../../../firebase-config'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { TailSpin } from  'react-loader-spinner'
import { Timestamp, addDoc, collection } from 'firebase/firestore'
import { useYdNipponContext } from '../../../../../context/YDNipponContext'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GiCarWheel, GiGearStick } from 'react-icons/gi';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function VehicaleAddForm() {
    const [selecEngineCapacity , setEngineCapacity]  = useState("");
    const [selecEngineModel , setEngineModel]  = useState("");
    const [selecMileage , setMilieage]  = useState("");
    const [selecChaseNo , setChaseNo]  = useState("");
    const [selecSeatsCount , setSeatsCount]  = useState(0);
    const [selecColour , setColour]  = useState("");
    const [selecSakken , setSakken]  = useState("");
    const [selectTransmission , setTransmission]  = useState("Auto");
    const [selectVehiDesc , setVehiDesc]  = useState("");
    
    const [ selectVehiWidth ,  setVehiWidth] = useState("")
    const [ selectVehiLength ,  setVehiLength] = useState("")
    const [ selectVehiTierCount ,  setVehiTierCount] = useState(0)
    const [ selectVehiTierSize , setVehiTierSize ] = useState(0)
    const [ selectVehiGear ,  setVehiGear] = useState(0)

    const [selectedImagesList , setSelectedImagesList]  = useState([])
    const [imageUploadingStatus  , setImageUploadingStatus] = useState(false)
    const [VehicaleUploadingStatus  , setVehicaleUploadingStatus] = useState(false)

    const [value, setValue] = React.useState(0);
    const [clicked , setClicked]  = useState("");

    const {setNotiStatus, setNotiError, setNotiContent }  = useYdNipponContext() 

    const photoRef = useRef()
    const vehicaleCollection = collection(db , 'vehicales')  


    const handleChange = (event  , newValue) => {
         setValue(newValue);
    };

    const handleClicked = (selectedItem) => {
        setClicked(selectedItem)
        if(selectedItem === clicked ){
          setClicked("")
        }
    }


    const handleEngineCapacity  = (e) => {
        setEngineCapacity(e.target.value)
    }

    const handleEngineModel = (e) => {
        setEngineModel(e.target.value)
    }

    const handleMileage = (e) => {
        setMilieage(e.target.value)
    }

    const handleChaseNo = (e) => {
        setChaseNo(e.target.value)
    }

    const handleSeats = (e) => {
        setSeatsCount(e.target.value)
    }

    const handleColour = (e) => {
        setColour(e.target.value)
    }

    const handleSakken = (e) => {
        setSakken(e.target.value)
    }

    const handleVehiDesc = (e) => {
        setVehiDesc(e.target.value)
    }
    
    const handleTransmission  = (itemSeelcted) => {
        setTransmission(itemSeelcted)
    }

    const handleVehiWidth = (e) => {
        setVehiWidth(e.target.value)
    }

    const handleVehiLength = (e) => {
        setVehiLength(e.target.value)
    }

    const handleVehiTiersCounts = (e) => {
        setVehiTierCount(e.target.value)
    }

    const handleVehiTierSize = (e) => {
        setVehiTierSize(e.target.value)
    }

    const handleVehiGear = (e) => {
        setVehiGear(e.target.value)
    }

    const handleFileOpen = () => {
        photoRef.current.click()
    }

    const removeImage = (imageToRemove) => {
        setSelectedImagesList(prevList => prevList.filter(image => image !== imageToRemove));
        setNotiContent("Image Has Been Removed") 
        setNotiStatus(true)
        setNotiError(true)
        setTimeout(() =>{
            setNotiStatus(false);
            setNotiError(false)
        },4000)
    };
    

    const handleUploadVehicale = (vehiTYpe) => {
        setVehicaleUploadingStatus(true)
        if(vehiTYpe === "Long-Vehicale"){
            console.log("Long Vehicale");
            if(selecEngineCapacity && selecEngineModel && selecChaseNo  && selecColour && selecMileage && selecSakken  && selectTransmission &&  selectVehiDesc && selectVehiWidth && selectVehiLength && selectVehiTierCount && selectVehiTierSize && selectVehiGear && (selectedImagesList.length >= 5) ){

                addDoc(vehicaleCollection , {
                    Images : selectedImagesList,
                    EningineCapacity : selecEngineCapacity,
                    EngineModel : selecEngineModel,
                    ChaseNo : selecChaseNo,
                    Colour : selecColour,
                    Mileage : selecMileage,
                    Sakken : selecSakken,
                    Transmission : selectTransmission,
                    Description : selectVehiDesc,
                    Width :  selectVehiWidth,
                    Length : selectVehiLength,
                    TierCount : selectVehiTierCount,
                    TierSize : selectVehiTierSize,
                    GaerCount : selectVehiGear,
                    VehicaleType : 'Long Vehicale',
                    ISfavourite : false,
                    creatDate:Timestamp.now().toDate()
                }).then(() => {
                    setNotiContent("Vehicale Uploaded Successfully") 
                    setNotiStatus(true)
                    setNotiError(false)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)

                        setChaseNo("")
                        setColour("")
                        setEngineCapacity("")
                        setEngineModel("")
                        setMilieage("")
                        setSakken("")
                        setTransmission("Auto")
                        setVehiDesc("")
                        setVehiWidth("")
                        setVehiLength("")
                        setVehiTierCount(0)
                        setVehiTierSize(0)
                        setVehiGear(0)
                        setSelectedImagesList([])
                    },4000)
                    
                }).catch((err) => {
                    console.log(err);
                    setNotiContent("Something Went Wrong Please Try Again Later") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                })

            }else{
                if(selectedImagesList.length < 5){
                    console.log(selectedImagesList.length)
                    setNotiContent("Please Select Atleat 5 Image") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectTransmission){
                    setNotiContent("Please Select Transmission") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiDesc){
                    setNotiContent("Please Enter Vehciale Description") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiGear){
                    setNotiContent("Please Select Gear Count") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiTierSize){
                    setNotiContent("Please Select Tier Size") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiTierCount){
                    setNotiContent("Please Select Tier Count") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiLength){
                    setNotiContent("Please Select Vehicale Length") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiWidth){
                    setNotiContent("Please Select Vehicale Width") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecSakken){
                    setNotiContent("Please Enter Sakken") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecColour){
                    setNotiContent("Please Enter Color")
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecChaseNo){
                    setNotiContent("Please Enter Chase No")
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecMileage){
                    setNotiContent("Please Enter Mileage") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecEngineModel){
                    setNotiContent("Please Enter Engine Model") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecEngineCapacity){
                    setNotiContent("Please Enter Engine Capacity") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
            
                
            }
        }else{
            console.log("Light Vehicale");
            if(selecEngineCapacity && selecEngineModel && selecChaseNo  && selecColour && selecMileage && selecSakken && selecSeatsCount && selectTransmission &&  selectVehiDesc && (selectedImagesList.length >= 5) ){

                addDoc(vehicaleCollection , {
                    Images : selectedImagesList,
                    EningineCapacity : selecEngineCapacity,
                    EngineModel : selecEngineModel,
                    ChaseNo : selecChaseNo,
                    Colour : selecColour,
                    Mileage : selecMileage,
                    Sakken : selecSakken,
                    SeatsCount : selecSeatsCount,
                    Transmission : selectTransmission,
                    Description : selectVehiDesc,
                    VehicaleType : 'Light Vehicale',
                    ISfavourite : false,
                    creatDate:Timestamp.now().toDate()
                }).then(() => {
                    setNotiContent("Vehicale Uploaded Successfully") 
                    setNotiStatus(true)
                    setNotiError(false)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)

                        setChaseNo("")
                        setColour("")
                        setEngineCapacity("")
                        setEngineModel("")
                        setMilieage("")
                        setSakken("")
                        setSeatsCount(0)
                        setTransmission("Auto")
                        setVehiDesc("")
                        setSelectedImagesList([])
                    },4000)
                    
                }).catch((err) => {
                    console.log(err);
                    setNotiContent("Something Went Wrong Please Try Again Later") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                })

            }else{
                if(selectedImagesList.length < 5){
                    console.log(selectedImagesList.length)
                    setNotiContent("Please Select Atleat 5 Image") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectVehiDesc){
                    setNotiContent("Please Enter Vehciale Description") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selectTransmission){
                    setNotiContent("Please Select Transmission") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecSakken){
                    setNotiContent("Please Enter Sakken") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecColour){
                    setNotiContent("Please Enter Color")
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecSeatsCount){
                    setNotiContent("Please Enter Seats Count") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecChaseNo){
                    setNotiContent("Please Enter Chase No")
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecMileage){
                    setNotiContent("Please Enter Mileage") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecEngineModel){
                    setNotiContent("Please Enter Engine Model") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
                if(!selecEngineCapacity){
                    setNotiContent("Please Enter Engine Capacity") 
                    setNotiStatus(true)
                    setNotiError(true)
                    setTimeout(() =>{
                        setVehicaleUploadingStatus(false)
                        setNotiStatus(false);
                        setNotiError(false)
                    },4000)
                }
            
                
            }
        }
    }

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0]
        setImageUploadingStatus(true)
        const stoarageRef = ref(storage , `/Vehicale/${Date.now()}${imageFile.name}` )
        const uploadImage = uploadBytesResumable(stoarageRef , imageFile)
        uploadImage.on("state_changed" , () => {

            },(error) =>{
                console.log(error)
            },() => {
                getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                    if(url){
                        setImageUploadingStatus(false)
                        setNotiContent("Image Successfully Uploaded") 
                        setNotiStatus(true)
                        setNotiError(false)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                        setTimeout(() => {
                            setSelectedImagesList(prevList => [...prevList, url]);
                        },4000)
                    }
                    }).catch((err) => {
                        console.log(err);
                        setNotiContent("Something Went Wrong Please Try again Later") 
                        setNotiStatus(true)
                        setNotiError(true)
                        setTimeout(() =>{
                            setNotiStatus(false);
                            setNotiError(false)
                        },4000)
                    })
            }
        )
    }

    return (
        <div className='vehical-add-form-container' >    
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                         value={value}
                         onChange={handleChange}
                         aria-label="basic tabs example"
                         sx={{
                           '& .MuiTabs-indicator': {
                             backgroundColor: '#B1252E',
                           },
                           '& .MuiTab-root': {
                             color: '#B1252E',
                             '&.Mui-selected': {
                               color: '#B1252E',
                             },
                           },
                         }}
                    >
                        <Tab label="Light Vehicale" {...a11yProps(0)} />
                        <Tab label="Long Vehciale" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <div className='vehicale-add-form-all-inputs'>
                        <div onClick={() => handleClicked("engineCapacity")} className={`custom-selector ${clicked === "engineCapacity" && 'clicked'}`} >
                                <label>Engine Capacity</label>
                                <div className='year-input'  >
                                    <input value={selecEngineCapacity} type='text' placeholder='Enter Engine Capacity' onChange={(e) => handleEngineCapacity(e)} />
                                    <PiEngineBold />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("engineModel")} className={`custom-selector ${clicked === "engineModel" && 'clicked'}`} >
                                <label>Engine Model</label>
                                <div className='year-input'  >
                                    <input value={selecEngineModel} type='text' placeholder='Enter Engine Model' onChange={(e) => handleEngineModel(e)} />
                                    <SiGoogleearthengine />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("mileage")} className={`custom-selector ${clicked === "mileage" && 'clicked'}`} >
                                <label>Mileage</label>
                                <div className='year-input'  >
                                    <input value={selecMileage} type='text' placeholder='Enter Mileage' onChange={(e) => handleMileage(e)} />
                                    <IoSpeedometerSharp  />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("chaseno")} className={`custom-selector ${clicked === "chaseno" && 'clicked'}`} >
                                <label>Chase No</label>
                                <div className='year-input'  >
                                    <input value={selecChaseNo} type='text' placeholder='Enter Chase No' onChange={(e) => handleChaseNo(e)} />
                                    <CiCreditCard1 style={{fontSize:'20px'}} />
                                </div>
                        </div>
                     
                        <div onClick={() => handleClicked("seats")} className={`custom-selector ${clicked === "seats" && 'clicked'}`} >
                                <label>Seats Count</label>
                                <div className='year-input Price-input'  >
                                    <input  value={selecSeatsCount} inputMode='numeric' type='number' placeholder='Enter Seats Count' onChange={(e) => handleSeats(e)} />
                                    <MdOutlineAirlineSeatReclineExtra  style={{fontSize:'20px'}} />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("color")} className={`custom-selector ${clicked === "color" && 'clicked'}`} >
                                <label>Colour</label>
                                <div className='year-input'  >
                                    <input value={selecColour} type='text' placeholder='Enter Colour' onChange={(e) => handleColour(e)} />
                                    <IoIosColorPalette />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("sakken")} className={`custom-selector ${clicked === "sakken" && 'clicked'}`} >
                                <label>Sakken</label>
                                <div className='year-input'  >
                                    <input value={selecSakken} type='text' placeholder='Enter Sakken' onChange={(e) => handleSakken(e)} />
                                    <CiCreditCard2 style={{fontSize:'20px'}} />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("transmission")} className={`custom-selector ${clicked === "transmission" && 'clicked'}`} >
                            <label>Transmission</label>
                            <div className='selected-option' >
                                <p className={`${clicked === "transmission"  && 'svg-clicked'}`} ><span>{selectTransmission}</span><BiSolidChevronDown/></p>
                            </div>
                            <div  className={`options  ${clicked === "transmission" && 'option-opened'}`}>
                                <div className='option-inner-container' >
                                    <div onClick={() => handleTransmission("Auto")} className={`each-option ${selectTransmission    === "Auto" && 'selected'}`} >Auto</div>
                                    <div onClick={() => handleTransmission("Manual")} className={`each-option ${selectTransmission    === "Manual" && 'selected'}`} >Manual</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-[20px] custom-selector ' >
                        <label>Vehicale Description</label>
                        <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                            <textarea value={selectVehiDesc}  onChange={(e) => handleVehiDesc(e)} rows={12} cols={20} type='text' placeholder='Enter Vehicale Description' className='px-4 py-4 text-[14px] text-[#757F95] w-full border-none outline-none placeholder:text-[#757F95]'  />
                        </div>
                    </div>

                    <div className='photo-upload-container custom-selector' >
                        <label>Upload Images</label>
                        <div  className='photo-upload-container-main'>
                            <input type='file' hidden ref={photoRef} onChange={(e) => handleImageUpload(e)} />
                            {
                                !imageUploadingStatus  ? (
                                    <div onClick={handleFileOpen} className='photo-ippload-container'>
                                        <p><BsImages /><span>Upload Vehicale Images</span></p>
                                    </div> 
                                ) 
                                : 
                                (
                                    <div className='photo-uploading-container' >
                                            <TailSpin
                                            height="40"
                                            width="40"
                                            color="#757F95"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                        <p>Uploading Image</p>
                                    </div>
                                )
                            }
                            
                        </div>
                     </div>
            
                    {
                        selectedImagesList.length !== 0 && (
                            <div className='uploaded-image-container' >
                                {
                                    selectedImagesList.map((image , index) => {
                                        console.log(index)
                                        return(
                                            <div key={index} className='each-image-uploaded' >
                                                <div onClick={() => removeImage(image)} className='svg-uploade-image' >
                                                    <MdDeleteOutline /> 
                                                </div>
                                                <img src={image} alt='uploaded-img' />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            
                        )
                    }
            
                    <button onClick={() => handleUploadVehicale("Light-Vehicale")} className='vehicale-add-Btn flex-btn'>
                        {
                            VehicaleUploadingStatus ?  
                            (
                                <div className='photo-uploading-container' >
                                            <TailSpin
                                            height="25"
                                            width="25"
                                            color="#FFF"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                </div>
                            )
                            :
                            (
                                <>
                                    <FiUpload /><span>Add Vehicale</span>  
                                </>
                            )
                        }
                    </button>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className='vehicale-add-form-all-inputs'>
                    <div onClick={() => handleClicked("engineCapacity")} className={`custom-selector ${clicked === "engineCapacity" && 'clicked'}`} >
                                <label>Engine Capacity</label>
                                <div className='year-input'  >
                                    <input value={selecEngineCapacity} type='text' placeholder='Enter Engine Capacity' onChange={(e) => handleEngineCapacity(e)} />
                                    <PiEngineBold />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("engineModel")} className={`custom-selector ${clicked === "engineModel" && 'clicked'}`} >
                                <label>Engine Model</label>
                                <div className='year-input'  >
                                    <input value={selecEngineModel} type='text' placeholder='Enter Engine Model' onChange={(e) => handleEngineModel(e)} />
                                    <SiGoogleearthengine />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("mileage")} className={`custom-selector ${clicked === "mileage" && 'clicked'}`} >
                                <label>Mileage</label>
                                <div className='year-input'  >
                                    <input value={selecMileage} type='text' placeholder='Enter Mileage' onChange={(e) => handleMileage(e)} />
                                    <IoSpeedometerSharp  />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("chaseno")} className={`custom-selector ${clicked === "chaseno" && 'clicked'}`} >
                                <label>Chase No</label>
                                <div className='year-input'  >
                                    <input value={selecChaseNo} type='text' placeholder='Enter Chase No' onChange={(e) => handleChaseNo(e)} />
                                    <CiCreditCard1 style={{fontSize:'20px'}} />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("color")} className={`custom-selector ${clicked === "color" && 'clicked'}`} >
                                <label>Colour</label>
                                <div className='year-input'  >
                                    <input value={selecColour} type='text' placeholder='Enter Colour' onChange={(e) => handleColour(e)} />
                                    <IoIosColorPalette />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("sakken")} className={`custom-selector ${clicked === "sakken" && 'clicked'}`} >
                                <label>Sakken</label>
                                <div className='year-input'  >
                                    <input value={selecSakken} type='text' placeholder='Enter Sakken' onChange={(e) => handleSakken(e)} />
                                    <CiCreditCard2 style={{fontSize:'20px'}} />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("width")} className={`custom-selector ${clicked === "width" && 'clicked'}`} >
                                <label>Vehical Width</label>
                                <div className='year-input'  >
                                    <input value={selectVehiWidth} type='text' placeholder='Enter Vehicale Width' onChange={(e) => handleVehiWidth(e)} />
                                    <AiOutlineColumnWidth />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("length")} className={`custom-selector ${clicked === "length" && 'clicked'}`} >
                                <label>Vehical Length</label>
                                <div className='year-input'  >
                                    <input value={selectVehiLength} type='text' placeholder='Enter Vehicale Length' onChange={(e) => handleVehiLength(e)} />
                                    <CiLineHeight />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("tiers")} className={`custom-selector ${clicked === "tiers" && 'clicked'}`} >
                                <label>Tiers Count</label>
                                <div className='year-input'  >
                                    <input value={selectVehiTierCount} inputMode='numeric' type='number' placeholder='Enter Vehicale Tiers Count' onChange={(e) => handleVehiTiersCounts(e)} />
                                    <GiCarWheel />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("size")} className={`custom-selector ${clicked === "size" && 'clicked'}`} >
                                <label>Tier Size</label>
                                <div className='year-input'  >
                                    <input value={selectVehiTierSize} inputMode='numeric' type='number' placeholder='Enter Vehicale Tiers Size' onChange={(e) => handleVehiTierSize(e)} />
                                    <IoRadioButtonOff />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("gear")} className={`custom-selector ${clicked === "gear" && 'clicked'}`} >
                                <label>Gears Count</label>
                                <div className='year-input'  >
                                    <input value={selectVehiGear} inputMode='numeric' type='number' placeholder='Enter Gears Count' onChange={(e) => handleVehiGear(e)} />
                                    <GiGearStick />
                                </div>
                        </div>

                        <div onClick={() => handleClicked("transmission")} className={`custom-selector ${clicked === "transmission" && 'clicked'}`} >
                            <label>Transmission</label>
                            <div className='selected-option' >
                                <p className={`${clicked === "transmission"  && 'svg-clicked'}`} ><span>{selectTransmission}</span><BiSolidChevronDown/></p>
                            </div>
                            <div  className={`options  ${clicked === "transmission" && 'option-opened'}`}>
                                <div className='option-inner-container' >
                                    <div onClick={() => handleTransmission("Auto")} className={`each-option ${selectTransmission    === "Auto" && 'selected'}`} >Auto</div>
                                    <div onClick={() => handleTransmission("Manual")} className={`each-option ${selectTransmission    === "Manual" && 'selected'}`} >Manual</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-[20px] custom-selector ' >
                        <label>Vehicale Description</label>
                        <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                            <textarea value={selectVehiDesc}  onChange={(e) => handleVehiDesc(e)} rows={12} cols={20} type='text' placeholder='Enter Vehicale Description' className='px-4 py-4 text-[14px] text-[#757F95] w-full border-none outline-none placeholder:text-[#757F95]'  />
                        </div>
                    </div>

                    <div className='photo-upload-container custom-selector' >
                        <label>Upload Images</label>
                        <div  className='photo-upload-container-main'>
                            <input type='file' hidden ref={photoRef} onChange={(e) => handleImageUpload(e)} />
                            {
                                !imageUploadingStatus  ? (
                                    <div onClick={handleFileOpen} className='photo-ippload-container'>
                                        <p><BsImages /><span>Upload Vehicale Images</span></p>
                                    </div> 
                                ) 
                                : 
                                (
                                    <div className='photo-uploading-container' >
                                            <TailSpin
                                            height="40"
                                            width="40"
                                            color="#757F95"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                        <p>Uploading Image</p>
                                    </div>
                                )
                            }
                            
                        </div>
                     </div>
            
                    {
                        selectedImagesList.length !== 0 && (
                            <div className='uploaded-image-container' >
                                {
                                    selectedImagesList.map((image , index) => {
                                        console.log(index)
                                        return(
                                            <div key={index} className='each-image-uploaded' >
                                                <div onClick={() => removeImage(image)} className='svg-uploade-image' >
                                                    <MdDeleteOutline /> 
                                                </div>
                                                <img src={image} alt='uploaded-img' />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            
                        )
                    }
            
                    <button onClick={() => handleUploadVehicale("Long-Vehicale")} className='vehicale-add-Btn flex-btn'>
                        {
                            VehicaleUploadingStatus ?  
                            (
                                <div className='photo-uploading-container' >
                                            <TailSpin
                                            height="25"
                                            width="25"
                                            color="#FFF"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                </div>
                            )
                            :
                            (
                                <>
                                    <FiUpload /><span>Add Vehicale</span>  
                                </>
                            )
                        }
                    </button>
                </CustomTabPanel>
            </Box>
                
                    
            
        </div>
    )
}

export default VehicaleAddForm