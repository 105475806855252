import React, { useState } from 'react'
import {BiSolidChevronDown , BiLogoTelegram} from 'react-icons/bi'
import {BsSearch , BsCalendarMinus , BsCurrencyYen} from 'react-icons/bs'
import './Request.css'
import {country ,  Brand , Car_model} from '../../../YdDb'
import { IoLogoWhatsapp } from "react-icons/io5";
import { useYdNipponContext } from '../../../context/YDNipponContext'

function Request() {
  const [clicked , setClicked]  = useState("");

  const {
    selectedCountry, 
    setSelectedCountry,
    selectedBrand, 
    setSelectedBrand,
    selectModel, 
    setSelectedModel,
    selectyear, 
    setSelectedYear,
    selectColur, 
    setSelectedColour,
    selectPrice, 
    setSelectedPrice,
    selectMilieage, 
    setSelectedMilieage,
    selectScore, 
    setSelectedScore,
    setReqMailBox,
    setPopupStatus,
    setNotiStatus,
    setNotiError,
    setNotiContent,
    selectUPdatedPrice, 
    setSelectedUPdatedPrice
  } = useYdNipponContext()


  const [fillterModel , setFillterModel] = useState(Car_model[selectedBrand])

  const handleClicked = (selectedItem) => {
      setClicked(selectedItem)
      if(selectedItem === clicked ){
        setClicked("")
      }
  }

  const handleSelectedContry  = (itemSeelcted) => {
        setSelectedBrand(Brand[itemSeelcted][0])
        setSelectedCountry(itemSeelcted)
        

        const handleBrand = Brand[itemSeelcted][0]
        setFillterModel(Car_model[handleBrand])
        setSelectedModel(Car_model[handleBrand][0])
  }

  const handleSelectedBrand  = (itemSeelcted) => {
    setSelectedModel(Car_model[itemSeelcted][0])
    setFillterModel(Car_model[itemSeelcted])
    setSelectedBrand(itemSeelcted)
  }

  const handleSelectYear  = (e) => {
    setSelectedYear(e.target.value)
  }

  const handleSelectColour  = (itemSeelcted) => {
    setSelectedColour(itemSeelcted)
  }

  const handleSelectPrice  = (e) => {
    setSelectedPrice(e.target.value)
  }

  const handleSelectScore  = (itemSeelcted) => {
    setSelectedScore(itemSeelcted)
  }


  const handleSelectModel = (itemSeelcted) => {
    setSelectedModel(itemSeelcted)
    setClicked("")

  }

  const handleSelectMilieage = (itemSeelcted) => {
    setSelectedMilieage(itemSeelcted)
  }

  const handleFillterModel = (e) => {
    const filltersmodels= Car_model[selectedBrand].filter((model) => model.toLowerCase().includes(e.target.value.toLowerCase()) )
    setFillterModel(filltersmodels)
  }

  const handleOpenRequestBox = () => {
    console.log(selectPrice)
    const parts = selectPrice.toString().split('.');
    console.log(parts)
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setSelectedUPdatedPrice(parts[0] + ' ¥')
    console.log(selectUPdatedPrice)

    if(selectColur && selectPrice && selectyear){

        setReqMailBox(true)
        setPopupStatus(true)
    }else{
        if(!selectyear){
            setNotiContent("Please Select Year")
            setNotiStatus(true)
            setNotiError(true)
            setTimeout(() =>{
                setNotiStatus(false);
                setNotiError(false)
            },4000)
        }
        if(!selectColur){
            setNotiContent("Please Select Color")
            setNotiStatus(true)
            setNotiError(true)
            setTimeout(() =>{
                setNotiStatus(false);
                setNotiError(false)
            },4000)
        }
        if(!selectPrice){
            setNotiContent("Please Select Price") 
            setNotiStatus(true)
            setNotiError(true)
            setTimeout(() =>{
                setNotiStatus(false);
                setNotiError(false)
            },4000)
        }
    }

  }

  return (
    <div className='container-custom flex justify-between  items-center gap-10 mt-24 request-mail-full-container' >
        <div className='request-container basis-[50%]' id='request'  data-aos="fade-right" data-aos-duration="800" >
            <div className='innner-container' >
                {/* <div className='request-head' >
                    <h3>Let's Request Your Perfect Car</h3>
                </div> */}
                <div className='vehicle-feature-selector' >

                    <div onClick={() => handleClicked("Country")} className={`custom-selector ${clicked === "Country" && 'clicked'}`} >
                        <label>Country</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Country"  && 'svg-clicked'}`} ><span>{selectedCountry}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options overflow-options ${clicked === "Country" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                {
                                    country.map((eachCountry) =>  (
                                            <div onClick={() =>  handleSelectedContry(eachCountry)} className={`each-option ${selectedCountry   === eachCountry && 'selected'}`} >{eachCountry}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div onClick={() => handleClicked("Brand")} className={`custom-selector ${clicked === "Brand" && 'clicked'}`} >
                        <label>Brand</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Brand"  && 'svg-clicked'}`} ><span>{selectedBrand}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options ${Brand[selectedCountry].length > 6 &&  'overflow-options'} ${clicked === "Brand" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                {
                                    Brand[selectedCountry].map((specificBarnd) => (
                                        <div onClick={() => handleSelectedBrand(specificBarnd)} className={`each-option ${selectedBrand  === specificBarnd && 'selected'}`} >{specificBarnd}</div>
                                    ))
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div  className={`custom-selector ${clicked === "Model" && 'clicked'}`} >
                        <label>Model</label>
                        <div className='selected-option' onClick={() => handleClicked("Model")} >
                            <p className={`${clicked === "Model"  && 'svg-clicked'}`} ><span>{selectModel }</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options  ${fillterModel.length > 6 &&  'overflow-options'} ${clicked === "Model" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                {
                                    Car_model[selectedBrand].length > 10 && (
                                        <div className='search-bar-model' >
                                                <input onChange={(e) => handleFillterModel(e)} type='text' placeholder='Search Model'/>
                                                <BsSearch />
                                        </div>
                                    )
                                }

                                {
                                    fillterModel.length === 0 && (
                                        <p className='no-results-found' >NO RESULTS FOUND</p>
                                    )
                                }
                                
                                {
                                    fillterModel.map((specificModel) => (
                                        <div onClick={() => handleSelectModel(specificModel)} className={`each-option ${selectModel   === specificModel && 'selected'}`} >{specificModel}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div onClick={() => handleClicked("Milieage")} className={`custom-selector ${clicked === "Milieage" && 'clicked'}`} >
                        <label>Km</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Milieage"  && 'svg-clicked'}`} ><span>{selectMilieage  }</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options overflow-options ${clicked === "Milieage" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                <div onClick={() => handleSelectMilieage("All Km")} className={`each-option ${selectMilieage    === "All Km" && 'selected'}`} >All Km</div>
                                <div onClick={() => handleSelectMilieage("1,000 Km")} className={`each-option ${selectMilieage    === "1,000 Km" && 'selected'}`} >1,000 Km</div>
                                <div onClick={() => handleSelectMilieage("5,000 Km")} className={`each-option ${selectMilieage    === "5,000 Km" && 'selected'}`} >5,000 Km</div>
                                <div onClick={() => handleSelectMilieage("10,000 Km")} className={`each-option ${selectMilieage    === "10,000 Km" && 'selected'}`} >10,000 Km</div>
                                <div onClick={() => handleSelectMilieage("20,000 Km")} className={`each-option ${selectMilieage    === "20,000 Km" && 'selected'}`} >20,000 Km</div>
                                <div onClick={() => handleSelectMilieage("30,000 Km")} className={`each-option ${selectMilieage    === "30,000 Kms" && 'selected'}`} >30,000 Km</div>
                                <div onClick={() => handleSelectMilieage("40,000 Km")} className={`each-option ${selectMilieage    === "40,000 Km" && 'selected'}`} >40,000 Km</div>
                                <div onClick={() => handleSelectMilieage("50,000 Km")} className={`each-option ${selectMilieage    === "50,000 Km" && 'selected'}`} >50,000 Km</div>
                                <div onClick={() => handleSelectMilieage("60,000 Km")} className={`each-option ${selectMilieage    === "60,000 Km" && 'selected'}`} >60,000 Km</div>
                                <div onClick={() => handleSelectMilieage("70,000 Km")} className={`each-option ${selectMilieage    === "70,000 Km" && 'selected'}`} >70,000 Km</div>
                                <div onClick={() => handleSelectMilieage("80,000 Km")} className={`each-option ${selectMilieage    === "80,000 Km" && 'selected'}`} >80,000 Km</div>
                                <div onClick={() => handleSelectMilieage("90,000 Km")} className={`each-option ${selectMilieage    === "90,000 Km" && 'selected'}`} >90,000 Km</div>
                                <div onClick={() => handleSelectMilieage("100,000 Km")} className={`each-option ${selectMilieage    === "100,000 Km" && 'selected'}`} >100,000 Km</div>
                                <div onClick={() => handleSelectMilieage("110,000 Km")} className={`each-option ${selectMilieage    === "110,000 Km" && 'selected'}`} >110,000 Km</div>
                                <div onClick={() => handleSelectMilieage("120,000 Km")} className={`each-option ${selectMilieage    === "120,000 Km" && 'selected'}`} >120,000 Km</div>
                                <div onClick={() => handleSelectMilieage("130,000 Km")} className={`each-option ${selectMilieage    === "130,000 Km" && 'selected'}`} >130,000 Km</div>
                                <div onClick={() => handleSelectMilieage("140,000 Km")} className={`each-option ${selectMilieage    === "140,000 Km" && 'selected'}`} >140,000 Km</div>
                                <div onClick={() => handleSelectMilieage("150,000 Km")} className={`each-option ${selectMilieage    === "150,000 Km" && 'selected'}`} >150,000 Km</div>
                                <div onClick={() => handleSelectMilieage("160,000 Km")} className={`each-option ${selectMilieage    === "160,000 Km" && 'selected'}`} >160,000 Km</div>
                                <div onClick={() => handleSelectMilieage("170,000 Km")} className={`each-option ${selectMilieage    === "170,000 Km" && 'selected'}`} >170,000 Km</div>
                                <div onClick={() => handleSelectMilieage("180,000 Km")} className={`each-option ${selectMilieage    === "180,000 Km" && 'selected'}`} >180,000 Km</div>
                                <div onClick={() => handleSelectMilieage("190,000 Km")} className={`each-option ${selectMilieage    === "190,000 Km" && 'selected'}`} >190,000 Km</div>
                                <div onClick={() => handleSelectMilieage("200,000 Km")} className={`each-option ${selectMilieage    === "200,000 Km" && 'selected'}`} >200,000 Km</div>
                            </div>
                        </div>
                    </div>
                    
                    <div onClick={() => handleClicked("Year")} className={`custom-selector ${clicked === "Year" && 'clicked'}`} >
                        <label>Year</label>
                        <div className='year-input'  >
                            <input value={selectyear} type='text' placeholder='Enter Year' onChange={(e) => handleSelectYear(e)} />
                            <BsCalendarMinus />
                        </div>
                    </div>
                    
                    <div onClick={() => handleClicked("Colur")} className={`custom-selector ${clicked === "Colur" && 'clicked'}`} >
                        <label>Color</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Colur"  && 'svg-clicked'}`} ><span>{selectColur}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options overflow-options ${clicked === "Colur" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                <div onClick={() => handleSelectColour("Pearl")} className={`each-option each-option-color ${selectColur    === "Pearl" && 'selected'}`} ><div className='color-each' style={{background:'#EAE0C8'}} ></div><span>Pearl</span></div>
                                <div onClick={() => handleSelectColour("White")} className={`each-option each-option-color ${selectColur    === "White" && 'selected'}`} ><div className='color-each' style={{background:'#F6F6F6'}} ></div><span>White</span></div>
                                <div onClick={() => handleSelectColour("Yellow")} className={`each-option each-option-color ${selectColur    === "Yellow" && 'selected'}`} ><div className='color-each' style={{background:'yellow'}} ></div><span>Yellow</span></div>
                                <div onClick={() => handleSelectColour("Orange")} className={`each-option each-option-color ${selectColur    === "Orange" && 'selected'}`} ><div className='color-each' style={{background:'orange'}} ></div><span>Orange</span></div>
                                <div onClick={() => handleSelectColour("Wine")} className={`each-option each-option-color ${selectColur    === "Wine" && 'selected'}`} ><div className='color-each' style={{background:'#722F37'}} ></div><span>Wine</span></div>
                                <div onClick={() => handleSelectColour("Pink")} className={`each-option each-option-color ${selectColur    === "Pink" && 'selected'}`} ><div className='color-each' style={{background:'pink'}} ></div><span>Pink</span></div>
                                <div onClick={() => handleSelectColour("Purple")} className={`each-option each-option-color ${selectColur    === "Purple" && 'selected'}`} ><div className='color-each' style={{background:'purple'}} ></div><span>Purple</span></div>
                                <div onClick={() => handleSelectColour("Blue")} className={`each-option each-option-color ${selectColur    === "Blue" && 'selected'}`} ><div className='color-each' style={{background:'blue'}} ></div><span>Blue</span></div>
                                <div onClick={() => handleSelectColour("Brown")} className={`each-option each-option-color ${selectColur    === "Brown" && 'selected'}`} ><div className='color-each' style={{background:'brown'}} ></div><span>Brown</span></div>
                                <div onClick={() => handleSelectColour("Balge")} className={`each-option each-option-color ${selectColur    === "Balge" && 'selected'}`} ><div className='color-each' style={{background:'#f5f5dc'}} ></div><span>Balge</span></div>
                                <div onClick={() => handleSelectColour("Gold")} className={`each-option each-option-color ${selectColur    === "Gold" && 'selected'}`} ><div className='color-each' style={{background:'gold'}} ></div><span>Gold</span></div>
                                <div onClick={() => handleSelectColour("Silver")} className={`each-option each-option-color ${selectColur    === "Silver" && 'selected'}`} ><div className='color-each' style={{background:'silver'}} ></div><span>Silver</span></div>
                                <div onClick={() => handleSelectColour("Gray")} className={`each-option each-option-color ${selectColur    === "Gray" && 'selected'}`} ><div className='color-each' style={{background:'gray'}} ></div><span>Gray</span></div>
                                <div onClick={() => handleSelectColour("Black")} className={`each-option each-option-color ${selectColur    === "Black" && 'selected'}`} ><div className='color-each' style={{background:'black'}} ></div><span>Black</span></div>
                                <div onClick={() => handleSelectColour("Other")} className={`each-option each-option-color ${selectColur    === "Other" && 'selected'}`} ><div className='color-each' style={{background:'#CBDCCB'}} ></div><span>Other</span></div>
                            </div>
                        </div>
                    </div>
                    
                    <div onClick={() => handleClicked("Price")} className={`custom-selector ${clicked === "Price" && 'clicked'}`} >
                        <label>Price</label>
                        <div className='year-input Price-input'  >
                            <input  value={selectPrice} inputMode='numeric' type='number' placeholder='Enter Price' onChange={(e) => handleSelectPrice(e)} />
                            <BsCurrencyYen style={{fontSize:'20px'}} />
                        </div>
                    </div>
                    

                    <div onClick={() => handleClicked("Scores")} className={`custom-selector ${clicked === "Scores" && 'clicked'}`} >
                        <label>Scores</label>
                        <div className='selected-option' >
                            <p className={`${clicked === "Scores"  && 'svg-clicked'}`} ><span>{selectScore}</span><BiSolidChevronDown/></p>
                        </div>
                        <div  className={`options h-[200px] overflow-y-scroll  ${clicked === "Scores" && 'option-opened'}`}>
                            <div className='option-inner-container' >
                                <div onClick={() => handleSelectScore("All Score")} className={`each-option ${selectMilieage    === "All Score" && 'selected'}`} >All Score</div>
                                <div onClick={() => handleSelectScore("3")} className={`each-option ${selectMilieage    === "3" && 'selected'}`} >3</div>
                                <div onClick={() => handleSelectScore("3.5")} className={`each-option ${selectMilieage    === "3.5" && 'selected'}`} >3.5</div>
                                <div onClick={() => handleSelectScore("4")} className={`each-option ${selectMilieage    === "4" && 'selected'}`} >4</div>
                                <div onClick={() => handleSelectScore("4.5")} className={`each-option ${selectMilieage    === "4.5" && 'selected'}`} >4.5</div>
                                <div onClick={() => handleSelectScore("s")} className={`each-option ${selectMilieage    === "s" && 'selected'}`} >s</div>
                            </div>
                        </div>
                    </div>

                </div>
                <button onClick={handleOpenRequestBox} className='primaryBtn mt-6 mailReqBtn w-full flex justify-center items-center py-8 gap-3'><BiLogoTelegram/><span>Request Car</span></button>
            </div>
        </div>
        <div className='basis-[50%] z-[-3]'   data-aos="fade-left" data-aos-duration="800" >
                <div>
                     <h1 className='text-xl md:text-2xl  font-bold' >Let's Request Your Perfect Vehicale</h1>
                     <p className='text-[15px] text-gray-500 mt-4' >At YDNippon Co. Ltd, we make it easy for you to find the car that suits your exact needs. Our vehicle request system allows you to customize your search by specifying your preferences, including the country of origin, brand, model, mileage, year, color, budget, and other criteria. Simply fill in the vehciale details and personal details, and click on the “Request Car” button. Our team will then review your request and contact you with the best available options, ensuring a smooth and tailored car-buying experience</p>
                </div>
                <button className='primaryBtn mt-5 grayBtn ' ><a  href="https://wa.me/+819050040518" target="_blank" rel="noreferrer" className='justify-center gap-1 items-center flex'><IoLogoWhatsapp color="#fff" size={18} /> <span>Reach Us</span></a></button>
        </div>

    </div>
  )
}

export default Request