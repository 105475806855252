import React from 'react'
import './PreLoader.css'
import { TailSpin } from  'react-loader-spinner'

function PreLoader() {
  return (
    <div>
            <span className='red-blur' ></span>
            <span className='orange-blur' ></span>
            <div className="loader">
                <TailSpin
                visible={true}
                height="30"
                width="30"
                color="#FFFF"
                secondaryColor= '#FFFFF'
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                />
            </div>
    </div>
  )
}

export default PreLoader