import React , {useEffect , useState} from 'react'
import logo from '../../../assets/logos/yd-nippon-white.png'
import {Menu , Send} from 'lucide-react'
import './nav.css'
import { useYdNipponContext } from '../../../context/YDNipponContext'
import AnchorLink from 'react-anchor-link-smooth-scroll' 

const NavBar = () => {
  const [navStatus , setNavStatus] = useState()
  const {setPopupStatus, setSiderbarStatus} = useYdNipponContext()

  const transtionNavBar = () => {
    if(window.scrollY > 300) {
      setNavStatus(true)
    }else{
      setNavStatus(false)
    }
  }  

  const handleSideBar = () => {
    setSiderbarStatus(true)
    setPopupStatus(true)
  }

  useEffect(() => {
    window.addEventListener("scroll" , transtionNavBar)
    
    return () => window.removeEventListener("scroll" , transtionNavBar)
  }, []) 

  return (
    <div className={`${navStatus ? 'w-full border-b nav-top' : 'w-full border-b bg-transparent'}`} >
        <div className='container-custom py-4 flex justify-between items-center' >
            <div>
                <img src={logo} className='object-cover w-[100px]' alt='yd-nippon-logo.png' />
            </div>
            <div className='flex justify-between items-center gap-10' >
                <ul className='hidden justify-between items-center gap-14 lg:flex' >
                    <li><a href='/' className='text-gray-300 hover:text-gray-50 ease-in' >Home</a></li>
                    <li><a href='/' className='text-gray-300 hover:text-gray-50 ease-in' >About</a></li>
                    <li><a href='/' className='text-gray-300 hover:text-gray-50 ease-in' >Contact</a></li>
                    <li><a href='/' className='text-gray-300 hover:text-gray-50 ease-in' >Service</a></li>
                    <li><a href='/' className='text-gray-300 hover:text-gray-50 ease-in' >Request</a></li>
                </ul>
                <div className='flex justify-between items-center gap-5' >
                     <button className='primaryBtn grayBtn hidden md:block' ><AnchorLink className='justify-center gap-3 items-center flex'  href='#contact' ><Send color="#fff" size={18} /> <span>Get A Quote</span></AnchorLink></button>
                     <Menu onClick={handleSideBar} color="#fff" size={35} className='cursor-pointer' />
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default NavBar