import React from 'react'

const Components = () => {
  return (
    <div className='p-8 w-full' > 
        <div className='my-4' >
            <h3 className='my-3' >Primary Button</h3>
            <div className='flex justify-start items-center gap-4' >
                <button className='primaryBtn' >Primary Button</button>
                <button className='primaryBtn grayBtn' >Primary Gray Button</button>
            </div>
        </div>
        <div className='my-4' >
            <h3 className='my-3' >Primary Black Button</h3>
            <button className='primaryBtn blackBtn' >Primary Black Button</button>
        </div>
        <div className='my-4' >
            <h3 className='my-3' >Primary White Button</h3>
            <button className='primaryBtn whiteBtn' >Primary White Button</button>
        </div>
        <div className='my-4' >
            <h3 className='my-3' >Secondary Button</h3>
            <button className='secondaryButton' >Secondary Button</button>
        </div>
        <div className='my-4' >
            <h3 className='my-3' >Blur Button</h3>
            <button className='blurButton active' >Blur Button</button>
        </div>
        <div className='my-4'>
            <h3 className='my-3' >Sub head</h3>
            <h3 className='text-xl' >About our <span className='subhead-mark' >Autohive</span></h3>
        </div>
    </div>
  )
}

export default Components