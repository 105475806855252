import React from 'react'
import './contact.css'
import { FaWhatsapp  , FaTwitter} from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";

const Contact = () => {
  return (
    <div className='mt-24 container-custom' id='contact' >
      <div className='flex justify-between flex-col lg:flex-row items-start gap-7' >
          <div className='basis-[65%] bg-[#fff] p-5 h-fit lg:h-[810px]'  data-aos="fade-right" data-aos-duration="800">
              <div className='my-3 flex justify-start items-start flex-col gap-4' >
                <h3>Need Help ? Send Message</h3>
                <p className='text-[#A2A2A2]' >It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin</p>
              </div>
              <div className='mt-7' >
                <div className='flex justify-between items-center flex-col xsm:flex-row gap-6' >
                    <div className='basis-[50%] w-full' >
                        <p className='font-semibold text-[16px]' >Name</p>
                        <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                          <input type='text' placeholder='Name' className='px-4 py-4 text-[14px] text-[#C2C2C2] w-full border-none outline-none placeholder:text-[#C2C2C2]'  />
                        </div>
                    </div>
                    <div className='basis-[50%] w-full' >
                        <p className='font-semibold text-[16px]' >Name</p>
                        <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                          <input type='text' placeholder='Name' className='px-4 py-4 text-[14px] text-[#C2C2C2] w-full border-none outline-none placeholder:text-[#C2C2C2]'  />
                        </div>
                    </div>
                </div>
                <div className='flex justify-between items-center flex-col xsm:flex-row gap-6 mt-4' >
                    <div className='basis-[50%] w-full' >
                        <p className='font-semibold text-[16px]' >Name</p>
                        <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                          <input type='text' placeholder='Name' className='px-4 py-4 text-[14px] text-[#C2C2C2] w-full border-none outline-none placeholder:text-[#C2C2C2]'  />
                        </div>
                    </div>
                    <div className='basis-[50%] w-full' >
                        <p className='font-semibold text-[16px]' >Name</p>
                        <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                          <input type='text' placeholder='Name' className='px-4 py-4 text-[14px] text-[#C2C2C2] w-full border-none outline-none placeholder:text-[#C2C2C2]'  />
                        </div>
                    </div>
                </div>
                <div>
                  <p>Message</p>
                  <div className='w-full bg-white input-border border-1 border-[#C2C2C2] my-3' >
                      <textarea rows={12} cols={20} type='text' placeholder='Name' className='px-4 py-4 text-[14px] text-[#C2C2C2] w-full border-none outline-none placeholder:text-[#C2C2C2]'  />
                  </div>
                </div>
              </div>
              <button className='primaryBtn' >Get In Touch</button>
          </div>
          <div className='basis-[35%] bg-[#fff] p-5 h-fit lg:h-[810px]' data-aos="fade-left" data-aos-duration="800">
              <h3>Contact Details</h3>

              <div className='mt-10 mb-9' >
                <div className='mb-5'>
                  <p className='leading-[30px]' ><span className='text-[#000] font-semibold' >Office Address-1 : </span> <span className='text-gray-500' >1-54 Miyoshi Yatomi-shi Aichi-ken Japan</span></p>
                </div>
                {/* <div>
                  <p className='leading-[30px]' ><span className='text-[#000] font-semibold' >Office Address-1 : </span> <span className='text-gray-500' >Autohive Car Delarship Agency (United Estate Of America) Co., Ltd. Bridge 8, Room 9201,</span></p>
                </div> */}
              </div>

              <div className='border-top pt-8 mb-9' >
                <div className='flex justify-start items-center gap-5' >
                    <div className='bg-[#f1b9bd] rounded-full p-3 w-fit' >
                      <FaWhatsapp className='text-[25px] text-[#B1252E]' />
                    </div>
                    <div>
                      <p className='text-gray-500' >Emergency Call</p>
                      <p className='font-bold text-[16px] lg:text-[20px]' >+90 54 3147 8789</p>
                    </div>
                </div>
                <div className='flex justify-start items-center gap-5 mt-4' >
                    <div className='bg-[#f1b9bd] rounded-full p-3 w-fit' >
                      <MdMailOutline className='text-[25px] text-[#B1252E]' />
                    </div>
                    <div>
                      <p className='text-gray-500' >General Communication</p>
                      <p className='font-bold text-[16px] lg:text-[20px]' >info@ydnippon.jp</p>
                    </div>
                </div>
              </div>
              
              <div className='border-top pt-8' >
                  <h5>Social Here</h5>
                  <div className='mt-4 flex justify-start gap-4 items-center' >
                      <div className='p-3 cursor-pointer social-border w-fit social-each-icon' >
                          <FaFacebookF className='text-[#7A7A7A] text-[20px]' />
                      </div>
                      <div className='p-3 cursor-pointer social-border w-fit social-each-icon' >
                          <FaTwitter className='text-[#7A7A7A] text-[20px]' />
                      </div>
                      <div className='p-3 cursor-pointer social-border w-fit social-each-icon' >
                          <FaWhatsapp className='text-[#7A7A7A] text-[20px]' />
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className='location-content'data-aos="fade-up" data-aos-duration="800" >
                <iframe title='location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3265.4771926966578!2d136.76092739999999!3d35.0698079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60038327ae0062b1%3A0xcd7ba67a6ff616c7!2sY%20D%20NIPPON!5e0!3m2!1sen!2sjp!4v1705694991356!5m2!1sen!2sjp" width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Contact