import React  from 'react'
import './VehicaleUpdatePage.css'
import {motion} from 'framer-motion'
import SideBar from '../components/SideBar/SideBar';
import Nav from '../components/Nav/Nav';
import PopupBlack from '../components/PopupBlack/PopupBlack';
import VehicaleUpdateForm from './components/VehicaleUpdateForm/VehicaleUpdateForm'
import Notification from '../../HomePage/Notification/Notification';
import { RxUpdate } from 'react-icons/rx'
import { BiArrowBack } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import { useYdNipponContext } from '../../../context/YDNipponContext';

function VehicaleUpdatePage() {
  const {adSideBar  ,  setPopupStatus , adSideBarStatusMobile} = useYdNipponContext()


  const handleSidebar = () => {
    setPopupStatus(false)
    adSideBarStatusMobile(false)
  }

  const params = useParams()

  return (
        <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>
           <SideBar page='vehicales'  dashbord='../admin/adminpanel' vehicale={`../admin/adminpanel/vehicale/update-vehicale/${params?.id}`} request = '../Admin/Adminpanel/Requested-Vehicale' favourite='../Admin/Adminpanel/Favourite-Vehicale'/>
           <div className={`main-admin-home vehicale-manage ${adSideBar  && 'main-admin-home-full'}`} >
                <Nav />
                <div className='main-admin-innner' >
                    <div className='main-admin-head'>
                      <h1><RxUpdate /><span>Update Vehicale</span></h1>
                      <Link to='../admin/adminpanel/vehicale'><BiArrowBack /><span>Back</span></Link>
                    </div>
                    <VehicaleUpdateForm />
                </div>
           </div>
           <PopupBlack handleSidebar={handleSidebar} />
           <Notification />
        </motion.div>
  )
}

export default VehicaleUpdatePage