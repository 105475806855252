import React from 'react'
import './counts.css'
import { FaRegHeart } from "react-icons/fa";

const Counts = () => {
    return (
        <div className='count-banner mt-24' >
            <div className='flex justify-center items-center gap-5 pt-5'>
                <h3 className='!text-white text-xl text-center font-bold'>
                    Interesting<span className='subhead-mark'> Numbers</span>
                </h3>
            </div>
            <div className='flex justify-center items-center gap-5 pt-4'>
                <h2 className='!text-white text-3xl md:text-4xl text-center font-bold'>Fun Facts By The Numbers</h2>
            </div>
            <div className='container-custom mt-10 grid-container-counts' >
                <div className='count flex justify-center items-center gap-4'  data-aos="fade-up" data-aos-duration="800">
                    <div className='p-6 bg-[#B1252E] rounded-full'  >
                        <FaRegHeart size={30} color='#fff' />
                    </div>
                    <div>
                        <h2 className='font-bold text-3xl' >3K+</h2>
                        <p className='text-gray-500 ' >Happy Customers</p>
                    </div>
                </div>
                <div className='count flex justify-center items-center gap-4' data-aos="fade-up" data-aos-duration="800" >
                    <div className='p-6 bg-[#B1252E] rounded-full'  >
                        <FaRegHeart size={30} color='#fff' />
                    </div>
                    <div>
                        <h2 className='font-bold text-3xl' >3K+</h2>
                        <p className='text-gray-500 ' >Happy Customers</p>
                    </div>
                </div>
                <div className='count flex justify-center items-center gap-4' data-aos="fade-up" data-aos-duration="800" >
                    <div className='p-6 bg-[#B1252E] rounded-full'  >
                        <FaRegHeart size={30} color='#fff' />
                    </div>
                    <div>
                        <h2 className='font-bold text-3xl' >3K+</h2>
                        <p className='text-gray-500 ' >Happy Customers</p>
                    </div>
                </div>
                <div className='count flex justify-center items-center gap-4' data-aos="fade-up" data-aos-duration="800" >
                    <div className='p-6 bg-[#B1252E] rounded-full'  >
                        <FaRegHeart size={30} color='#fff' />
                    </div>
                    <div>
                        <h2 className='font-bold text-3xl' >3K+</h2>
                        <p className='text-gray-500 ' >Happy Customers</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Counts;