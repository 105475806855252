import React , {useEffect} from "react";
import {Routes , Route} from 'react-router-dom'
import HomePage from "./pages/HomePage";
import Components from "./pages/Components";
import LoginPage from  './pages/AdminPanel/LoginPage/LoginPage'
import Aos from 'aos' 
import 'aos/dist/aos.css'
import { useYdNipponContext } from "./context/YDNipponContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase-config";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import PrivateRoute from './utils/PrivateRoute'
import VehicaleUpdatePage from "./pages/AdminPanel/VehicaleUpdatePage/VehicaleUpdatePage"
import FavrtVehicalePage from "./pages/AdminPanel/FavrtVehicalePage/FavrtVehicalePage";
import VehicaleRequest from "./pages/AdminPanel/VehicaleRequest/VehicaleRequest";
import AdminHomePage from './pages/AdminPanel/AdminHomePage/AdminHomePage';
import VehicaleManagePage from "./pages/AdminPanel/VehicaleManagePage/VehicaleManagePage";
import VehicaleAddPage from "./pages/AdminPanel/VehicaleAddPage/VehicaleAddPage";

function App() {
  const {setUser ,setIsLoged , isLoged ,setAllVehicales  , setFillterdVehicales , allVehicales , setFavrtVehicales  , setClientRequest} = useYdNipponContext() 
  const vehicaleCollection = collection(db , 'vehicales')  

  useEffect(() => {
    Aos.init({})  
  },[])   

  useEffect(() => {
      const unSubscribe = onAuthStateChanged(auth , (user) => {
          if(user){
            setIsLoged(true)
            console.log(user.email)
            setUser(user.email)
          }else{
            setIsLoged(false)
          }
      })

      return unSubscribe
  },[setIsLoged , isLoged])

  useEffect(() => {
    const vehicalQ = query(vehicaleCollection, orderBy("creatDate" , "desc"))
   // const requestQ = query(requestsCollection, orderBy("creatDate" , "desc"))

    onSnapshot(vehicalQ , (snaps) => {
        setAllVehicales(snaps.docs.map((doc) => ({...doc.data() , id:doc.id}) ))
    })

    console.log(allVehicales);

    // onSnapshot(requestQ , (snaps) => {
    //     setClientRequest(snaps.docs.map((doc) => ({...doc.data() , id:doc.id}) ))
    // })

    
  },[])

  useEffect(() => {
    const filteredArray = allVehicales.map((obj) => {
        return {
            id : obj.id,
            Image : obj.Images[0],
            EningineCapacity : obj.EningineCapacity,
            EngineModel : obj.EngineModel,
            Colour : obj.Colour,
            Mileage : obj.Mileage,
            ChaseNo : obj.ChaseNo,
            Transmission : obj.Transmission,
            VehicaleType : obj.VehicaleType ,
            ISfavourite : obj.ISfavourite,
        };
    });
    setFillterdVehicales(filteredArray)

    const fillterdvehicales = allVehicales.filter((vehi) => vehi.ISfavourite === true)
    setFavrtVehicales(fillterdvehicales)
},[allVehicales])


  return (
    <Routes>
      <Route element={<HomePage />} path="/" />
      <Route element={<LoginPage />} path="/admin/adminpanel/login" />
      <Route element={<Components />} path="/components" />
      <Route element={<PrivateRoute />}>
            <Route element={<AdminHomePage />} path="/admin/adminpanel" />
            <Route element={<VehicaleManagePage />} path="/admin/adminpanel/vehicale" />
            <Route element={<VehicaleAddPage />} path="/admin/adminpanel/vehicale/add-vehicale" />
            <Route element={<FavrtVehicalePage />} path="/admin/adminpanel/favourite-vehicale" />
            <Route element={<VehicaleRequest />} path="/admin/adminpanel/requested-vehicale" />
            <Route element={<VehicaleUpdatePage />} path="/admin/adminpanel/vehicale/update-vehicale/:id" />
      </Route>
    </Routes>
  );
}

export default App;
