import React, { useEffect, useState } from 'react'
import TopBar from './TopBar/TopBar'
import Banner from './Banner/Banner'
import Contact from './Contact/Contact'
import WhyChoose from './WhyChoose/WhyChoose'
import CarGallery from './CarGallery/CarGallery'
import Service from './Service/Service'
import About from './About/About'
import Counts from './Counts/Count'
import Brand from './Brands/Brand'
import Footer from './Footer/Footer'
import Request from './Request/Request'
import Sidebar from './Sidebar/Sidebar'
import PopupBalck from './PopupBlack/PopupBalck'
import Notification from './Notification/Notification'
import MailRequestBox from './MailRequestBox/MailRequestBox'
import ImageSlider from './ImageSlider/ImageSlider'
import PreLoader from './PreLoader/PreLoader'
import {motion} from 'framer-motion'

const HomePage = () => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <>
    {
      false ? 
      <PreLoader />
      :
      <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}}>

                <Sidebar />
                <PopupBalck />
                <Notification />
                <MailRequestBox />
                <ImageSlider />
                <TopBar />
                <Banner />
                <Request />
                <WhyChoose />
                <About />
                <CarGallery />
                <Counts />
                <Service />
                <Brand />
                <Contact />
                <Footer />
      </motion.div>  
    }
    </>
  )
}

export default HomePage