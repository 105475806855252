import React from 'react'
import './Nav.css'
import {GiHamburgerMenu} from 'react-icons/gi'
import {BiLogOut} from 'react-icons/bi'
import { signOut } from 'firebase/auth'
import { auth } from '../../../../firebase-config'
import { useYdNipponContext } from '../../../../context/YDNipponContext'


function Nav() {
  const {adSideBarStatus , adSideBar , setPopupStatus  , adSideBarStatusMobile , adSideBarMobile } = useYdNipponContext()

  const handleSideBarDesktop = () => {
    adSideBarStatus(!adSideBar)
  }

  const handleSideBarPhone = () => {
    setPopupStatus(true)
    adSideBarStatusMobile(!adSideBarMobile)
  }

  const handleLogout = async () => {
    await signOut(auth)
  }


  return (
    <div className='ad-nav' >
        <div className='ad-nav-inner-conatiner' >
            <GiHamburgerMenu onClick={handleSideBarDesktop} className='desktop-menu admin-menu'/>
            <GiHamburgerMenu onClick={handleSideBarPhone} className='mobile-menu admin-menu'/>
            <button onClick={handleLogout} className='Logout-btn'>
               <BiLogOut />
               <span>Logout</span>
            </button>
        </div>
    </div>
  )
}

export default Nav