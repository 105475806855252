import React from 'react'
import { MdCurrencyYen } from "react-icons/md";
import serviceImg  from '../../../assets/services/video-bg.png'

const Service = () => {
  return (
    <div data-aos="fade-up"  data-aos-duration="800" className='container-custom bg-white custom-box-shaodw xxxsm:h-[480px] h-[540px] mt-24 flex justify-between items-center rounded-sm overflow-hidden' >
        <div className='basis-[100%] py-[80px]  px-[20px] lg:pl-[50px] w-[100%] lg:basis-[50%]' >
            <h3 className='text-xl font-semibold'>
              Buy Dream <span className='subhead-mark'>Vehicale</span>
            </h3>
            <h1 className='pt-6 text-2xl  xxsm:text-3xl xsm:text-4xl font-bold' >Feel the best Experience With our Rental Deals</h1>

            <div className='mt-[40px]' >
                <div className='flex justify-start items-start gap-4' >
                    <div className='p-[16px] bg-[#B1252E] rounded-md' >
                        <MdCurrencyYen color='#fff' size={30} />
                    </div>
                    <div>
                        <h3 className='font-bold xxsm:text-xl xsm:text-2xl text-[18px]' >Deals for Every Budget</h3>
                        <p className='text-gray-400 mt-1 text-[14px]' >Seamlessly predominate out-of-the-box services through Collaboratively deploy innovative.</p>
                    </div>
                </div>
                <div className='flex justify-start items-start gap-4 mt-4' >
                    <div className='p-[16px] bg-[#B1252E] rounded-md' >
                        <MdCurrencyYen color='#fff' size={30} />
                    </div>
                    <div>
                        <h3 className='font-bold xxsm:text-xl xsm:text-2xl text-[18px]' >Deals for Every Budget</h3>
                        <p className='text-gray-400 mt-1 text-[14px]' >Seamlessly predominate out-of-the-box services through Collaboratively deploy innovative.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='basis-[50%] relative overflow-hidden hidden lg:block h-full w-full'>
            <img src={serviceImg} alt='serviceImg' className='object-cover absolute h-full w-full' />
        </div>
    </div>
  )
}

export default Service