import React from 'react'
import './ShowaCaseCount.css'
import {FaCar  , FaTelegramPlane} from 'react-icons/fa'
import { AiOutlineHeart} from 'react-icons/ai'
import {Link} from 'react-router-dom'
import { useYdNipponContext } from '../../../../../context/YDNipponContext'

function ShowaCaseCount() {
  const {allVehicales , favrtVehicales , clinetRequest} = useYdNipponContext() 
  
  return (
    <div className='show-case-container' >

        <Link to='../Admin/Adminpanel/Vehicale' className='sc-each sc-each-01' >
            <div className='sc-each-contnet' >
                <p>{allVehicales?.length}</p>
                <h4>Available Vehicales</h4>
            </div>
            <div className='sc-each-svg'>
                <FaCar  />
            </div>
        </Link>
        <Link to='../Admin/Adminpanel/Favourite-Vehicale'  className='sc-each sc-each-02' >
            <div className='sc-each-contnet' >
                <p>{favrtVehicales?.length}</p>
                <h4>Favourite Vehicale</h4>
            </div>
            <div className='sc-each-svg'>
                <AiOutlineHeart />
            </div>
        </Link>
        <Link to='../Admin/Adminpanel/Requested-Vehicale'   className='sc-each sc-each-03' >
            <div className='sc-each-contnet' >
                <p>{clinetRequest?.length}</p>
                <h4>Requested Vehicale</h4>
            </div>
            <div className='sc-each-svg'>
                <FaTelegramPlane />
            </div>
        </Link>

    </div>
  )
}

export default ShowaCaseCount