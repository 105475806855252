import React from 'react'
import './Brand.css'
import { AiFillCaretUp } from "react-icons/ai";
import brand01 from '../../../assets/brands/01.webp'
import brand02 from '../../../assets/brands/02.png'
import brand03 from '../../../assets/brands/03.png'
import brand04 from '../../../assets/brands/04.png'
import brand05 from '../../../assets/brands/05.png'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr"

const brands = [ brand01 , brand02 , brand03 , brand04  , brand05 , brand01 , brand02 , brand03 , brand04  , brand05 ]

const NextArrow = (props) => {
    const { onClick } = props
  
    return (
      <div className="access-slider next-one">
        <button className="next" onClick={onClick}>
          <GrFormNextLink color="white" />
        </button>
      </div>
    )
  }
  
  const PrevArrow = (props) => {
    const { onClick } = props
  
    return (
      <div className="access-slider prev-one">
        <button className="prev" onClick={onClick}>
          <GrFormPreviousLink color="white" />
        </button>
      </div>
    )
}

const settings = {
    className: "center",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: "60px",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

const Brand = () => {
  return (
    <div className='mt-24 container-custom brand-banner overflow-hidden rounded-lg' data-aos="fade-up" data-aos-duration="800"  >
        <div className='flex justify-between items-center gap-8 flex-col lg:flex-row' >
            <div className='basis-[30%]' >
                <div>
                    <h1 className='text-white text-3xl font-bold leading-[40px]' >YD Nippon Vehicale Supplier</h1>
                    <p className='text-gray-400 my-3 text-[14px]' >Welcome to NYK Trading your partner for top quality vehicle imports and exports from Japan. With a focus on transparency and customer</p>
                    <button className='primaryBtn flex justify-center gap-1 items-center' ><AiFillCaretUp size={20} color='#fff' /><span>Gallery</span></button>
                </div>
            </div>
            <div className='basis-[70%] w-full lg:w-[300px] relative' >
                <Slider {...settings}>
                    {
                        brands.map((brand) => (
                            <EachBarnd image={brand} />
                        ))
                    }
                </Slider>
            </div>
        </div>
    </div>
  )
}

function EachBarnd({ image}){
    return(
        <div className='each-brand' >
                <img src={image} alt='brand01' />
        </div>
    )
}

export default Brand